@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

@font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-CondensedBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-stretch: condensed;
  }
  


  @font-face {
    font-family: 'UfcSansRegular';
    src: url('../../Fonts/UFCSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-RegularOblique.woff2') format('woff2');
    font-weight: normal;
    font-style: oblique;
  }


.thankyou-wrapper{
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #0a0806;  
    overflow-x: hidden;
}
.content{
    padding-top:200px;
    padding-left: 50px;
    flex-basis: 50%;
    text-align: left;
}
.thankyou-wrapper .content h1{
    color:#fff;
    margin-bottom: 15px;
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
  
    text-transform: uppercase;

}
.thankyou-wrapper .content p{
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 26px;
    font-weight: 100;
    color:#ffc000;

    font-family: 'Bungee', sans-serif;
    
}



.thankyou-wrapper .imgwrap{
    flex-basis: 50%;
    display: flex;
    align-items: flex-end;
padding-top:80px;
    margin-right: -200px;
    
}
.imgwrap img{
    width:100%;
    
}