@import url('https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap');

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
}



@font-face {
  font-family: 'UfcSansRegular';
  src: url('../../Fonts/UFCSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-RegularOblique.woff2') format('woff2');
  font-weight: normal;
  font-style: oblique;
}


.userdashboard{
    min-height: 100vh;
    display: flex;

    background-image: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)),url('../../Assets/RING3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    text-align: center;
    flex-direction: column;
    
}
.fightItem{
    margin-bottom: 30px;
    cursor: pointer;
    transition: 0.4s;
    
}

.fightItem:hover{
    transform: scale(1.02);
    
}
.fightsWrap{
     width:100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top:15px;
    gap:30px;
    padding-bottom: 100px;
    overflow-x: hidden;
    flex-wrap: wrap;
}
.fightscontainer{
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
}
.fightscontainer .fightsheadingone , .fightscontainer .fightsheadingthree , .fightscontainer .fightsheadingtwo {
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; /* Condensed style */
 font-size:24px;
 margin-bottom: 10px;  
color: #eee;
text-align: center;
padding:10px 0;
width:100%;
border:3px solid #ccc;
border-radius: 30px;
text-transform:uppercase;
padding-top: 12px;
margin-bottom: 20px;
}

.upcomingFights .fightsheadingone{
    background-color: #5e0a0a;
}
.completedFights .fightsheadingtwo{
    background-color: #0b4c80;
}
.pendingFights .fightsheadingthree{
    background-color: #0d8c17;
}













.upcomingFights .fightItemOne{
    display: flex;
    gap:7px;
}



.upcomingFights .transformed-div {
    height: 50px;
    width: 250px;
    background-color: #fff;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    display: flex;
    align-items: center;
    
}
.upcomingFights .fightItemOne .transformed-div h1{
    text-transform: uppercase;
    color: #d30c23;
    margin-left: 46%;

    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    

    font-size: 15px;
    }
    .upcomingFights .transformed-div-two {
    height: 50px;
    width: 200px;
    background-color: #decf00;
    clip-path: polygon(6% 0, 100% 0, 95% 100%, 0 100%);
    margin-left: -15px;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    padding-top: 5px;
    gap:1px;
}
.upcomingFights .transformed-div-two-partOne{
    display: flex;
    gap: 10px;
}

.upcomingFights .transformed-div-two-partOne h1{
    font-size: 19px;
    text-transform: uppercase;
    color: black;

    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    
}
.upcomingFights .transformed-div-two-partTwo{
    display: flex;
    gap: 10px;
}

.upcomingFights .transformed-div-two-partTwo h1{
    font-size: 15px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 700;
    text-transform: uppercase;
    color: #388218;
}

.upcomingFights .transformed-div-two-partTwo p{
    font-size: 15px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 900;
    text-transform: uppercase;
    color: black;
}




.upcomingFights .fightItemTwo{
    display: flex;

}
.upcomingFights .transformed-div-three {
    height: 46px;
    width: 280px;
    background-color: #c5c5c5;
    margin-right: 6px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    }
    .upcomingFights  .transformed-div-three p{
        
        font:bold 14px / 15px 'Trebuchet Ms', sans-serif;
        text-shadow: none;
    
    }
    .upcomingFights .transformed-div-four {
        height: 46px;
        width: 120px;
        background-color: #c5c5c5;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
    }
    .upcomingFights .transformed-div-four h1{
    font-size: 19px;
    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    
    text-transform: uppercase;
    color: #d30c23;
    }
    .upcomingFights .transformed-div-four p{
    font-size: 18px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 900;
    
    }

    .upcomingFights  .fightItem .fightersImages{
        position: absolute;
        top:-5px;
        left:-12px;
        display: flex;
        gap:2px;
    }
    .upcomingFights  .fighterOne{
        width:60px;
        height: 60px;
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border:3px solid blue;
        background-color: #fff;
        
    }
    .upcomingFights  .fighterOne img{
        width:100%;
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        
        
    }
    
    
    .upcomingFights .fighterTwo{
        width:60px;
        height: 60px;
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border:3px solid red;
        
        background-color: #fff;
    
        
    }
    .upcomingFights .fighterTwo img{
        width:100%;
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        
    }
    



















    






.completedFights .fightItemOne{
    display: flex;
    gap:7px;
}



.completedFights .transformed-div {
    height: 50px;
    width: 250px;
    background-color: #878787;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    display: flex;
    align-items: center;
    
}
.completedFights .fightItemOne .transformed-div h1{
    text-transform: uppercase;
    color: #fff;
    margin-left: 46%;

    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    

    font-size: 15px;
    }
    .completedFights .transformed-div-two {
    height: 50px;
    width: 200px;
    background-color: #ffffff;
    clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%);
    margin-left: -15px;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    padding-top: 5px;
    gap:1px;
}
.completedFights .transformed-div-two-partOne{
    display: flex;
    gap: 10px;
}

.completedFights .transformed-div-two-partOne h1{
    font-size: 19px;
    text-transform: uppercase;
    color: black;

    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    
}
.completedFights .transformed-div-two-partTwo{
    display: flex;
    gap: 10px;
}

.completedFights .transformed-div-two-partTwo h1{
    font-size: 15px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 700;
    text-transform: uppercase;
    color: #388218;
}

.completedFights .transformed-div-two-partTwo p{
    font-size: 15px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 900;
    text-transform: uppercase;
    color: black;
}




.completedFights .fightItemTwo{
    display: flex;

}
.completedFights .transformed-three {
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .completedFights .transformed-three   .transformedDivBox{
        height: 46px;
        width: 45px;
        background-color: #c5c5c5;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .completedFights .transformed-three .transformedDivBox:last-of-type {
        height: 46px;
        width: 65px;
        font-size: 13px;
    }
    
   .transformed-five{
    display: flex;
    align-items: left;
    justify-content: left;
    padding-left: 50px;
   }
   
    .transformed-five   .transformedDivBox{
    height: 40px;
    width: 45px;
    background-color: #ebebeb;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

}
   
   
   
    .completedFights .transformed-div-four {
        height: 46px;
        width: 120px;
        background-color: #c5c5c5;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
    }
    .completedFights .transformed-div-four h1{
    font-size: 19px;
    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    
    text-transform: uppercase;
    color: #d30c23;
    }
    .completedFights .transformed-div-four p{
    font-size: 18px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 900;
    
    }

    .completedFights  .fightItem .fightersImages{
        position: absolute;
        top:-5px;
        left:-12px;
        z-index: 1;
        display: flex;
        gap:2px;
    }
    .completedFights  .fighterOne{
        width:60px;
        height: 60px;
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border:3px solid blue;
        
background-color: #fff;
        
    }
    .completedFights  .fighterOne img{
        width:100%;
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        
        
    }
    
    
    .completedFights .fighterTwo{
        width:60px;
        height: 60px;
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border:3px solid red;
        
background-color: #fff;
    
        
    }
    .completedFights .fighterTwo img{
        width:100%;
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        
    }
    


















































    

    .pendingFights .fightItemOne{
    display: flex;
    gap:7px;
}



.pendingFights .transformed-div {
    height: 50px;
    width: 250px;
    background-color: #6f942f;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    display: flex;
    align-items: center;
    
}
.pendingFights .fightItemOne .transformed-div h1{
    text-transform: uppercase;
    color: #fff;
    margin-left: 46%;

    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    

    font-size: 15px;
    }
    .pendingFights .transformed-div-two {
    height: 50px;
    width: 200px;
    background-color: #ffffff;
    clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%);
    margin-left: -15px;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    padding-top: 5px;
    gap:1px;
}
.pendingFights .transformed-div-two-partOne{
    display: flex;
    gap: 10px;
}

.pendingFights .transformed-div-two-partOne h1{
    font-size: 19px;
    text-transform: uppercase;
    color: black;

    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    
}
.pendingFights .transformed-div-two-partTwo{
    display: flex;
    gap: 10px;
}

.pendingFights .transformed-div-two-partTwo h1{
    font-size: 15px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 700;
    text-transform: uppercase;
    color: #388218;
}

.pendingFights .transformed-div-two-partTwo p{
    font-size: 15px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 900;
    text-transform: uppercase;
    color: black;
}




.pendingFights .fightItemTwo{
    display: flex;

}
.pendingFights .transformed-three {
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .pendingFights .transformed-three   .transformedDivBox{
        height: 46px;
        width: 45px;
        background-color: #c5c5c5;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .pendingFights .transformed-three   .transformedDivBox:last-of-type {
        height: 46px;
        width: 65px;
    }
    .pendingFights .transformed-five{
    display: flex;
    align-items: left;
    justify-content: left;
    padding-left: 50px;
   }
   
   .pendingFights   .transformedDivBox{
    height: 40px;
    width: 45px;
    background-color: #ebebeb;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

}
   
   
   
.pendingFights .transformed-div-four {
        height: 46px;
        width: 120px;
        background-color: #c5c5c5;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
    }
    .pendingFights .transformed-div-four h1{
    font-size: 19px;
    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    
    text-transform: uppercase;
    color: #d30c23;
    }
    .pendingFights .transformed-div-four p{
    font-size: 18px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 900;
    
    }

    .pendingFights  .fightItem .fightersImages{
        position: absolute;
        top:-5px;
        left:-12px;
        z-index: 1;
        display: flex;
        gap:2px;
    }
    .pendingFights  .fighterOne{
        width:60px;
        height: 60px;
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border:3px solid blue;
        
background-color: #fff;
        
    }
    .pendingFights  .fighterOne img{
        width:100%;
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        
        
    }
    
    
    .pendingFights .fighterTwo{
        width:60px;
        height: 60px;
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border:3px solid red;
        
background-color: #fff;
    
        
    }
    .pendingFights .fighterTwo img{
        width:100%;
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        
    }
    



 


    @media (max-width: 767px){
    .fightwalletWrap .fightWallet h2{
            font-size: 18px;
        }

        .fightsWrap{
           align-items: center;
           gap:40px;
           flex-wrap: nowrap;
           flex-direction: column;
           margin-top: 50px;

       }
       .fightscontainer{
           flex-basis: 100%;
           
       }
       .fightscontainer .fightsheadingone {
         font-size:20px;
       padding:8px 0;
       width:88%;
       padding-top: 12px;
       }
       .fightscontainer .fightsheadingthree {
        font-size:20px;
      padding:8px 20px;
      width:100%;
      padding-top: 12px;
      }
    .fightscontainer .fightsheadingtwo {
        font-size:20px;
      padding:10px 30px;
      width:100%;
      padding-top: 12px;
      }





      
      .upcomingFights .transformed-div {
    height: 40px;
    width: 170px;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    
}

.upcomingFights .fightItemOne{
    display: flex;
    gap:0;
}

.upcomingFights .fightItemOne .transformed-div h1{
    margin-left: 45%;
    font-size: 13px;
    }
    .upcomingFights .transformed-div-two {
    height: 40px;
    width: 170px;
    clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
    margin-left: -3px;
    padding-left: 20px;
    padding-top: 3px;
    gap:1px;
}
.upcomingFights .transformed-div-two-partOne{
    display: flex;
    gap: 10px;
}

.upcomingFights .transformed-div-two-partOne h1{
    font-size: 17px; 
}
.upcomingFights .transformed-div-two-partTwo{
    gap: 10px;
}

.upcomingFights .transformed-div-two-partTwo h1{
    font-size: 14px;
  }

  .upcomingFights .transformed-div-two-partTwo p{
    font-size: 14px;
    font-weight: 900;
   }



   .upcomingFights .transformed-div-three {
    height: 41px;
    width: 220px;
    margin-right: 10px;
    padding-left: 10px;
    }
    .upcomingFights .transformed-div-four {
        height: 41px;
        width: 80px;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        padding-right: 20px;
        margin-left: -5px;
    }
    .upcomingFights .transformed-div-four h1{
    font-size: 15px;
    }
    .upcomingFights .transformed-div-four p{
    font-size: 14px;
    font-weight: 900;
    }



    .upcomingFights .fightItem .fightersImages{
    position: absolute;
    top:-4px;
    left:-8px;
    z-index: 1;
    display: flex;
    gap:2px;
}
.upcomingFights .fighterOne{
    width:40px;
    height: 40px;
    
}


.upcomingFights .fighterTwo{
    width:40px;
    height: 40px;
}












.completedFights{
    margin-top:50px;
}
.completedFights .fightItemOne{
    display: flex;
    gap:7px;
}

.completedFights .transformed-div {
    height: 40px;
    width: 170px;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    
}
.completedFights .fightItemOne .transformed-div h1{
    margin-left: 45%;
    font-size: 13px;
}
.completedFights .transformed-div-two {
        height: 40px;
        width: 170px;
        clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
        padding-left: 20px;
        padding-top: 3px;
        gap:1px;
    
}
.completedFights .transformed-div-two-partOne{
    display: flex;
    gap: 10px;
}

.completedFights .transformed-div-two-partOne h1{
    font-size: 17px;    
}
.completedFights .transformed-div-two-partTwo{
    display: flex;
    gap: 10px;
}

.completedFights .transformed-div-two-partTwo h1{
    font-size: 14px;
}

.completedFights .transformed-div-two-partTwo p{
    font-size: 13px;
    font-weight: 900;
}




.completedFights .fightItemTwo{
    display: flex;

}
.completedFights .transformed-three {
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .completedFights .transformed-three   .transformedDivBox{
        height: 33px;
        width: 32px;
        margin-right: 3px;
        font-size:13px;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .completedFights .transformed-three   .transformedDivBox:last-of-type {
        height: 33px;
        width: 45px;
    }
    .completedFights .transformed-five{
    display: flex;
    align-items: left;
    justify-content: left;
    padding-left: 35px;
   }
   
   .completedFights   .transformedDivBox{
    height: 33px;
    width: 32px;
    margin-right: 3px;
    
    font-size:13px;
    display: flex;
    justify-content: center;
    align-items: center;

}
   
   
   
.completedFights .transformed-div-four {
    height: 33px;
    width: 80px;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    padding-right: 20px;
    padding-top:5px;
    }
    .completedFights .transformed-div-four h1{
        font-size: 15px;
    }

    .completedFights .transformed-div-four p{
        font-size: 14px;
        font-weight: 900;
    }


    .completedFights  .fightItem .fightersImages{
        position: absolute;
        top:-4px;
        left:-8px;
        z-index: 1;
        display: flex;
        gap:2px;
    }
    .completedFights  .fighterOne{
    width:40px;
    height: 40px;
    }
    
    
    .completedFights .fighterTwo{
        width:40px;
    height: 40px;
    }
    




























.pendingFights{
    margin-top:50px;
}
.pendingFights .fightItemOne{
    display: flex;
    gap:7px;
}

.pendingFights .transformed-div {
    height: 40px;
    width: 170px;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    
}
.pendingFights .fightItemOne .transformed-div h1{
    margin-left: 45%;
    font-size: 13px;
}
    .pendingFights .transformed-div-two {
        height: 40px;
        width: 170px;
        clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
        padding-left: 20px;
        padding-top: 3px;
        gap:1px;
    
}
.pendingFights .transformed-div-two-partOne{
    display: flex;
    gap: 10px;
}

.pendingFights .transformed-div-two-partOne h1{
    font-size: 17px;    
}
.pendingFights .transformed-div-two-partTwo{
    display: flex;
    gap: 10px;
}

.pendingFights .transformed-div-two-partTwo h1{
    font-size: 14px;
}

.pendingFights .transformed-div-two-partTwo p{
    font-size: 13px;
    font-weight: 900;
}




.pendingFights .fightItemTwo{
    display: flex;

}
.pendingFights .transformed-three {
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .pendingFights .transformed-three   .transformedDivBox{
        height: 33px;
        width: 32px;
        margin-right: 3px;
        font-size:13px;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .pendingFights .transformed-three   .transformedDivBox:last-of-type {
        height: 33px;
        width: 45px;
    }
    .pendingFights .transformed-five{
    display: flex;
    align-items: left;
    justify-content: left;
    padding-left: 35px;
   }
   
   .pendingFights   .transformedDivBox{
    height: 33px;
    width: 32px;
    margin-right: 3px;
    
    font-size:13px;
    display: flex;
    justify-content: center;
    align-items: center;

}
   
   
   
.pendingFights .transformed-div-four {
    height: 33px;
    width: 80px;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    padding-right: 20px;
    padding-top:5px;
    }
    .pendingFights .transformed-div-four h1{
        font-size: 15px;
    }

    .pendingFights .transformed-div-four p{
        font-size: 14px;
        font-weight: 900;
    }


    .pendingFights  .fightItem .fightersImages{
        position: absolute;
        top:-4px;
        left:-8px;
        z-index: 1;
        display: flex;
        gap:2px;
    }
    .pendingFights  .fighterOne{
    width:40px;
    height: 40px;
    }
    
    
    .pendingFights .fighterTwo{
        width:40px;
    height: 40px;
    }
    

        
}

.fightItem {
    position: relative;
  }
  
  .removeButton {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 10;
    display: none; /* Hidden by default */
  }
  
  .fightItem:hover .removeButton {
    display: block; /* Show button on hover */
  }
  

.transformedDivBoxLast{
    height: 40px;
    width: 115px;
    margin-right: 3px;
    font-size:13px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: black;

}

.transformed-Six{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 82.5%;
    text-align: center;
    background-color: #fff;
    margin-left: 50px;
    height: 40px;
    padding:10px;
   }


   .blink-text {
    animation: blink 1s step-start infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  .shareYourExperience{
    position: fixed;
    bottom: 15px;
    left: 20px;
    display: flex;
    gap:10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .shareYourExperience i{
    color: #decf00;
    font-size: 28px;
  }
  .shareYourExperience h1{
    color: #fff;
    font-size: 25px;
    font-family: 'UfcSansRegular';
    font-weight: 600;
   }
   .popup-overlay-dashboard {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-dashboard {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .popup-dashboard h2 {
    margin: 0 0 10px;
    font-size: 18px;
  }
  
  .popup-dashboard textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
  }
  
  .popup-actions-dashboard {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .popup-actions-dashboard button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-actions-dashboard button:first-child {
    background-color: #4caf50;
    color: white;
  }
  
  .popup-actions-dashboard button:last-child {
    background-color: #f44336;
    color: white;
  }
  