.makePredictions{
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url('../../Assets/RING3.png');
}
.flexColumn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.makePredictions .fightersImagesInFightDetails{
    margin-top:20px;
    gap:90px;
}

.makePredictions .fightersImagesInFightDetails h1{
    font-size:30px;
}
.makePredictions .fightersImagesInFightDetails .fightTypeInFightDetails{
    font-size: 24px;
}

.makePredictions .roundsWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    
}

.makePredictions .roundsWrapper .roundActual{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:15px;
    width:330px;
    margin:40px;
}
.makePredictions .roundsWrapper .roundActual .roundHeading{
    width:90%;
    text-align: center;
    border-bottom: 2px solid #c0c0c0;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.makePredictions .roundsWrapper .roundActual .roundHeading h1{
    border-radius: 15px 15px 0 0;
    border:2px solid #c0c0c0;
    width:150px;
    padding:5px 10px;
    background-color: rgb(8, 8, 105);
    font-size: 18px;
    color: #c1ce11;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}
.makePredictions .roundInputWrap{
    width:100%;
    border:2px solid #c0c0c0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:8px;
    padding:10px 0;
}
.makePredictions .roundInputWrap .roundInput{
    display: flex;
    justify-content: space-between;
   width:100%;
   padding:0 15px;
}
.roundinput-image .roundInputImgWrap{
    width:35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    padding: 5px;
}
.roundinput-image .roundInputImgWrap img{
    width: 100%;
    object-fit: cover;
    height: 100%;

}
.roundinput-image h2{
    color:#fff;
    font-size: 15px;
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; /* Condensed style */
    
}
.roundInputDivOne{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.roundInputDivOne input{
    width:80px;
    height: 35px;
    border-radius: 30px;
    
}
.fa-caret-left{
    font-size:40px;
    color:#2189e1;
}

.fa-caret-right{
    font-size:40px;
    color:#d91610;
}


.roundInputSpecial{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}









@media (max-width: 767px){
    .makePredictions .fightDetailsContainer{
        margin-top:20px !important;
    }
    .makePredictions .fightTypeInFightDetails, .beiginningTimeFight{
        font-size: 22px !important;
    }
    .makePredictions .btn-grad{
        width: 80%;
        margin-bottom: 50px;
    }
}