
@font-face {
  font-family: 'UFCSans-condensedmed';
  src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
}
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
.header {
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 40px;
    width: 100%;
    position: fixed;
    z-index: 9;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
   
  }
  .logoimg {
    width: 80px;
  }
  .logoimg img {
    width: 100%;
  }
  .anchorlinks {
    color: #e3e3e3;
    font-family: 'Bungee', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 0px;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 20px;
    padding-bottom: 10px;
    position: relative; /* For positioning the ::after pseudo-element */
  }
  
.anchorlinks::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px; /* Fixed height of the border */
  background-color: #d20a0a; /* Border color */
  transform: scaleY(0); /* Start with 0 scale (invisible) */
  transform-origin: bottom; /* Originates from the bottom */
  transition: transform 0.3s ease; /* Smooth transition */
}
.anchorlinks:hover{
  color:#fff;
}
.anchorlinks:hover::after {
  transform: scaleY(1); /* Full scale on hover */
}
  
  .sideLinkswrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .sideLinkswrap .joinNowBtn{
    background: linear-gradient(216.01deg, #d20a0a 26.18%, #9e0808 93.27%);
    color: #fff;
    cursor: pointer;
    font-family: 'UfcSansRegular';
    font-weight: 600;
    font-size: 22px;
    outline: none;
    padding: 12px 24px;
    text-align: center;
    width: 165px;
    text-decoration: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .25);

  }
  /* Container styles */
.scrolling-text-container {
  position: fixed;
  width: 100%;
  background: linear-gradient(216.01deg, #d20a0a 26.18%, #9e0808 93.27%);
  color: #fff;
  font-family: 'UFCSans-condensedmed';
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  padding: 10px 0;
  overflow: hidden; /* Hide anything outside the container */
  z-index: 999;
}

/* Inner container styles */
.scrolling-text-inner {
  display: flex;
  white-space: nowrap;
  font-size: 22px;
  font-weight: 500;
  animation: var(--direction) var(--marquee-speed) linear infinite;
}

/* Text container styles */
.scrolling-text {
  display: flex;
}

/* Text item styles */
.scrolling-text-item {
  padding: 0 5px; /* Space between repeated text */
}
/* Keyframes for scrolling left */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Keyframes for scrolling right */
@keyframes scroll-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Pause the animation when a user hovers over it */
.scrolling-text-container:hover .scrolling-text-inner {
  animation-play-state: paused;
}

  .sideLinks {
    color: #d2d2d2;
    font-family: 'Bungee', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 0px;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sideLinks i {
    margin-right: 5px;
    font-size: 28px;
    font-weight: 100;
  }
  .activeLink {
    color: #d20a0a;
  }
  .menuIconFont {
    display: none;
  }
  .mobileMenu {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
  }
  /* Mobile menu active state */
  .mobileMenu.active {
    display: flex;
    transform: translateY(0);
  }
  /* Close menu icon */
  .closeMenuIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    z-index: 1001;
  }
  /* Mobile-specific styling */
  @media (max-width: 767px) {
    .menuIconFont {
      display: block;
    }
    .menuIconFont i {
      color: #fff;
      font-size: 30px;
      z-index: 1001;
    }
    .anchorLinksWrapper,
    .sideLinkswrap {
      display: none;
    }
    .mobileMenu.active .anchorLinksWrapper,
    .mobileMenu.active .sideLinkswrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .mobileMenu .logoimg {
      margin-bottom: 40px;
    }
    .mobileMenu .anchorlinks,
    .mobileMenu .sideLinks {
      color: #fff;
      font-size: 20px;
      margin: 15px 0;
      text-align: center;
    }
  }
  
  .admin-header {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 230px;
    background-color: black;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 15px 0;
    overflow: auto;
}

/* For WebKit-based browsers (Chrome, Safari) */
.admin-header::-webkit-scrollbar {
    width: 3px; /* Adjust scrollbar width to 5px */
}

.admin-header::-webkit-scrollbar-track {
    background: black; /* Background of the scrollbar track */
}

.admin-header::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Scrollbar color */
    border-radius: 10px; /* Rounded edges */
}

/* For Firefox */
.admin-header {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #ccc black; /* Scrollbar color: #ccc, Track color: black */
}

.anchorLinksWrapperAdmin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.anchorLinksWrapperAdmin h1{
    color: #ffc000;
    font-family: UFCSans, Arial Narrow, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
}
.anchorLinksWrapperAdmin .anchorlinksAdmin{
    color: #fff;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: capitalize;
    text-decoration: none;
    margin:10px 0;
}
.sideLinksAdmin{
    color: #fff;
    font-family: 'Bungee', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: uppercase;
    text-decoration: none;
    margin:10px 0;
}
.matchHeading{
    margin-top:40px;
}


.topheadercheckbox{
    width:20px;
    height: 20px;
    position: absolute;
    left:300px;
    z-index: 99999;
    top:40px;
}





@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}


.adminLogo{
  display: none;
}

.menuIconFontAdmin{
 display: none;
}
.fightsubmenu{
  position: relative;
}
.fightsubmenu .submenu{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:15px;
  position: absolute;
  top: 61px; /* Adjust to set the submenu below "Fights" */
  left: -88%;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  padding: 15px 0;
  padding-top:20px;
  z-index: 1000;
  width: 200px;
  border-top:5px solid red;
  opacity: 0;
  transform: translateY(-10px);
}

/* Open submenu with animation */
.fightsubmenu .submenuOpen {
  animation: fadeInDown 0.3s ease forwards; /* Slide and fade in */
}

/* Close submenu with animation */
.fightsubmenu .submenuClosedclass {
  opacity: 0;
  animation: fadeOutUp 0.3s ease; /* Slide and fade out */
}
.fightsubmenu .submenu .submenuLink{
    color:#fff;
    text-decoration: none;
    font-size: 14px;
    padding-bottom: 8px;
    border-bottom: 2px solid #ccc;
}

@media (max-width: 767px){
.activeAdmin{
  display: flex;
}
.activeNotAdmin{
  display: none;
}
.adminLogo{
  position: absolute;
  top:15px;
  left: 30px;
  display: block;
}
.adminLogo img{
  width: 70px;
}

.menuIconFontAdmin{
  display: block;
  position: fixed;
  top:30px;
  right: 30px;
  color: #fff;
  font-size: 30px;
  z-index: 1001;
}
}



.activeLinkAdmin {
  color: #fff !important;
  background-color: #800020;
  text-align: center;
  padding:8px 20px;
  border-radius: 8px;
}

/* Keyframes for animations */
@keyframes fadeInPopup {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}@keyframes bounceIn {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}


@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Popup animation */
.container-promotion-absolute-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 550px;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000; /* Ensure it's above other content */
  background-image: url(../../Assets/imagesss.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.85);
  animation: fadeInPopup 0.8s ease-out;
}

/* Close button styles */
.container-promotion-absolute-center .close-button {
  position: absolute;
  top: 5px;
  right: -70px;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  border-radius: 50%;
}
.container-promotion-absolute-center .close-button:hover {
  color: #ff4d4d;
}

/* Image animation */
.imgpromotionContainer {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow-y: hidden;
  padding-top:50px;
}
.container-promotion-absolute-center img {
  max-height: 200px;
  margin-top: 60px;
  animation: bounceIn 1.2s ease-out infinite;
  animation-delay: 0s; /* No initial delay */
  animation-iteration-count: infinite; /* Loop forever */
  animation-timing-function: ease-out; /* Smooth transition */
  animation-fill-mode: forwards; /* Keep final state */
  animation-duration: 2xs;
  z-index: 1;
  margin-bottom: 20px;
}
/* Signup button with WhatsApp gradient background */
.container-promotion-absolute-center .signup-button {
  padding: 14px 30px;
  font-size: 22px;
  font-weight: bold;
  color: #fff; /* White text */
  background: linear-gradient(45deg, #128C7E, #075E54, #25D366, #34B7F1); /* WhatsApp gradient */
  background-size: 300% 300%; /* Ensures smooth gradient transitions */
  border: none;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  cursor: pointer;
  transition: background-position 0.5s ease, box-shadow 0.3s ease, transform 0.3s ease;
  animation: gradientAnimation 5s ease infinite; /* Gradient animation */
  z-index: 9;
}

/* Hover effect */
.container-promotion-absolute-center .signup-button:hover {
  transform: translateY(-2px); /* Slight lift effect */
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
}

/* Focus effect for accessibility */
.container-promotion-absolute-center .signup-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(18, 140, 126, 0.4); /* Focus ring matching teal green */
}

/* Gradient animation */
@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}



/* Text animation */
.container-promotion-absolute-center p {
  width: 80%;
  text-align: center;
  color: #fff;
  margin-top: 15px;
  font-family: 'UfcSansRegular';
  font-size: 20px;
  animation: fadeInText 1s ease-out 0.5s; /* Delayed fade-in */
  animation-fill-mode: both; /* Ensures the animation stays at the final state */
}

@media (max-width: 767px) {
  .container-promotion-absolute-center {
    width: 95%;
    height: auto;
    max-width: none;
    border-radius: 6px;
    font-size: 14px; /* Adjust font size for better readability */
    background-position: center;
    background-size: cover;
  }

  .container-promotion-absolute-center .close-button {
    top: -10px;
    right: 0;
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .imgpromotionContainer{
    width: 100%;
    height:120px;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-promotion-absolute-center img{
    max-height: 200px;
    overflow: hidden;
    margin-top:70px;
  }
  .container-promotion-absolute-center .signup-button {
    padding: 8px 20px;
    font-size: 14px;
   
  }

  .container-promotion-absolute-center p {
    width: 100%;
    margin-top: 15px;
    font-size: 13px;
  }
}


.mobileMenu .mobileAnchorLinks{
  font-family: 'UFCSans';
  margin:5px 0;
}