.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensures it stays above other elements */
  }
  
  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow for a lifted look */
    text-align: center;
  }
  
  .popup-content p {
    font-size: 1.1em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .popup-content button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s;
  }
  
  .popup-content button:hover {
    background-color: #0056b3;
  }
  
  .popup-content button:last-child {
    background-color: #ccc;
    color: #333;
  }
  
  .popup-content button:last-child:hover {
    background-color: #999;
  }
  