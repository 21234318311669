.shadowLibrary .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.shadowLibrary .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.shadowLibrary .popup-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.shadowLibrary .popup-content p {
    font-size: 18px;
    margin: 10px 0;
}

.shadowLibrary .popup-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.shadowLibrary .popup-actions button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.shadowLibrary .popup-actions button:hover {
    background-color: #0056b3;
}

.shadowLibrary table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.shadowLibrary table th,.shadowLibrary  table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.shadowLibrary table th {
    background-color: #f4f4f4;
    font-size: 16px;
    font-weight: bold;
}

.shadowLibrary table td img {
    border-radius: 50%;
}

.shadowLibrary button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.shadowLibrary button:hover {
    background-color: #0056b3;
}



.fixdDivShadowLibrary{
    position:fixed;
    z-index:999;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background:black;
}
.fixDivShadowTitle{
    width:30%;
    margin-bottom: 0 !important;
}
.shadowFightLibraryIcon{
    
    position: fixed;
    top: 38px;
    left: 18%;
    cursor: pointer;
    font-size: 24px;
     color: #007bff;  
     z-index: 99999; 
}


/* Responsive Design */
@media (max-width: 768px) {
   
    
.shadowFightLibraryIcon{
    
    top: 145px;
    left: 13%;
    font-size: 24px;
    color: red;
   }

    .fixDivShadowTitle{
        width:100%;
    }
    .shadowLibrary .popup-content {
        width: 100%;
        margin: 0 10px;
    }

    .shadowLibrary table th,.shadowLibrary  table td {
        padding: 8px;
    }

    .shadowLibrary  table img {
        width: 40px;
    }

    .shadowLibrary .popup-actions button {
        font-size: 10px;
    }
    

}
