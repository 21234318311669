.popupwrapped{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height:500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.popupwrapped h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
    
}

.popupwrapped p {
    margin-bottom: 20px;
    color: #666;
    font-size: 1rem;
}
.popupwrapped img{
    width:130px;
    margin:20px 0;
    border-radius: 50%;
}
.popupwrapped button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin:0 10px;
}

.popup button.cancel {
    background-color: #dc3545;
   
}

.popup button:hover {
    background-color: #0056b3;
}

.popup button.cancel:hover {
    background-color: #c82333;
}
