.finishedFightUserBoard .leaderboardHeading h3{
    width:250px;
}
.roundResultsWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    gap:25px;
    margin-top:30px;
}
.roundResultsWrapper .roundResultDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:51%;
    background-color: #c5c5c5;
    border-radius: 15px;
    border:1px solid gray;
    padding: 5px;
}
.roundResultsWrapper .roundResultDiv h1{
    font-size: 19px;
    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    
    text-transform: uppercase;
   
}
.roundResultsWrapper .roundResultDiv .line{
    width:100%;
    height:1px;
    background-color: rgba(0,0,0,0.5);
    margin:1px 0;
}
.roundResultsWrapper .roundResultDiv .scoresWrapper{
    display: flex;
    gap:7px;
    justify-content: center;
    align-items: center;
}
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
/* Common styles for h2 */
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound h2 {
    font-family: 'Trebuchet MS', sans-serif;
    text-shadow: none;
    font-size: 17px;
    margin-bottom: 0;
}

/* Common styles for h2 */
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound h3 {
    font-family: 'Trebuchet MS', sans-serif;
    text-shadow: none;
    font-size: 17px;
    margin-top:21px;
    margin-bottom: 9px;
    color:black;
}

/* For all even .scoresOfRound elements */
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound:nth-child(even) h2 {
    color: #d91610;
}

/* For all odd .scoresOfRound elements */
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound:nth-child(odd) h2 {
    color: #2189e1;
}

/* For all even .scoreBox elements */
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound:nth-child(even) .scoreBox {
    border: 2px solid #2189e1;
}

/* For all odd .scoreBox elements */
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound:nth-child(odd) .scoreBox {
    border: 2px solid #d91610;
}

/* Common styles for .scoreBox */
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound .scoreBox {
    width: 45px;
    height: 35px;
    margin-top: -10px;
    background-color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Common styles for .scoreBox */
.roundResultsWrapper .roundResultDiv .scoresWrapper .scoresOfRound .scoreBoxSpecial {
    width: 85px;
    height: 35px;
    background-color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1.5px solid black;
}
.winnerDiv{
    width:55%;
    display: flex;
    justify-content: space-between;
    
}
.winnerSuDivbOne{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px;

}
.winnerSuDivbOne .winnerImg{
    width:70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border:2px solid blue;
}
.winnerSuDivbOne .winnerImg img{
    width:100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.winnerDetails{
    text-align: left;
}
.winnerDetails h1{
    margin:0;
    color: #ffc000;
    font-size: 22px;
    font-family: 'Trebuchet MS', sans-serif;
    text-shadow: none;
    margin-top:20px;
    
}

.winnerDetails h2{
    margin:0;
    color: #fff;
    font-size: 16px;
    font-family: 'Trebuchet MS', sans-serif;
    text-shadow: none;
    margin-top:-15px;
    
}
.winnerSibDivTwo h1{
    color: #fff;
    font-size: 18px;
    font-family: 'Trebuchet MS', sans-serif;
    text-shadow: none;
    margin-top: 30px;
}

.winnerSibDivTwo h2{
    
    color: #ffc000;
    font-size: 18px;
    font-family: 'Trebuchet MS', sans-serif;
    text-shadow: none;
    margin-top:-5px;
}


.finishedFightUserBoard iframe{
    width:560px;
    height:315px;
}






@media (max-width: 767px){
    .finishedFightUserBoard .fightwalletWrap{
        flex-direction: column-reverse;
        gap:20px;
    }
    .finishedFightUserBoard .fightTypeInFightDetails{
        font-size: 22px !important;
    }
    
.finishedFightUserBoard iframe{
    width:340px;
    height:315px;
    margin: 20px 0;
}

.roundResultsWrapper{
    width:100%;
    gap:25px;
    margin-top:30px;
}
.roundResultsWrapper .roundResultDiv{
    width:95%;
    padding: 5px;
}
.roundResultsWrapper .roundResultDiv .scoresWrapper{
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    margin-bottom: 5px;
    overflow: auto;
    
}


.winnerDiv{
    width:95%;    
}
.winnerDetails h1{
    font-size: 19px;
    margin-top:24px;
}

.winnerDetails h2{
    font-size: 15px;
    margin-top:-15px;
    
}
.winnerSibDivTwo h1{
    font-size: 18px;
    margin-top: 34px;
}

}