@import url('https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap');

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
}



@font-face {
  font-family: 'UfcSansRegular';
  src: url('../../Fonts/UFCSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-RegularOblique.woff2') format('woff2');
  font-weight: normal;
  font-style: oblique;
}


.fightDetails{
    min-height: 100vh;
    display: flex;
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../../Assets/GLOVES2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    text-align: center;
    flex-direction: column;
    color:rgb(6, 6, 71)
    
}
.fightDetailsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:15px;
    margin-top:1px;
}
.fightDetailsContainer .fightDetailsContainerFirstHeading{
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; /* Condensed style */
    font-size:26px;
    color: #eee;
    text-transform:uppercase;

}

.fightDetailsContainer .fightDetailsContainerFirstHeading span{
    color:#e4c707;
}
.fightDetailsContainer .fightersImagesInFightDetails{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
}
.fightersImagesInFightDetails h1{
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:condensed; /* Condensed style */
    font-size:24px;
    color: #eee;
    text-transform:uppercase;
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
   
}
.imgWrapFights{
    width:100px;
    height:100px;
    border-radius: 50%;
    border:2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgWrapFights img{
    width:100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.fightDetailsContainer .fightTypeInFightDetails{
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; /* Condensed style */
    font-size:26px;
    color: #eee;
    text-transform:uppercase;
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
}
.fightDetailsContainer .fightDetailsPot{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
}
.fightDetailsContainer .fightDetailsPot h1, .fightDetailsContainer .fightDetailsPot p{
    font-style:normal; /* Condensed style */
    font-size:24px;
    color: #eee;
    text-transform:uppercase;
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
}
.fightDetailsContainer .beiginningTimeFight{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;

}
.fightDetailsContainer .beiginningTimeFight h1 , 
.fightDetailsContainer .beiginningTimeFight p{
    font-style:normal; /* Condensed style */
    font-size:24px;
    color: #eee;
    text-transform:uppercase;
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
}

.fightDetailsContainer .makeGreen{
    color:#38b90c;
}






.fightwalletWrap{
    width:100%;
    display: flex;
    justify-content: right;
    align-items: right;
    color: #ccc;
    margin-top:20px;
    padding-right: 20px;
}


.fightwalletWrap .fightWallet h1{
    font-size: 24px;
    margin-bottom: 5px;
}
.fightwalletWrap .fightWallet h2{
    padding: 7px 10px;
    border:2px solid #ccc;
}

.fightwalletWrap .fightWallet h2 span{
    padding: 2px 10px;
    background-color: #ccc;
    color:black;
}

.promobtn{
    width: 14%;
}
@media (max-width: 767px){
.specialtextmine{
   width:300px;
   overflow: auto;
}
}