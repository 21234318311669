@import url('https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap');

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
}



@font-face {
  font-family: 'UfcSansRegular';
  src: url('../../Fonts/UFCSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-RegularOblique.woff2') format('woff2');
  font-weight: normal;
  font-style: oblique;
}


.login-wrapper{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../Assets/RING1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    text-align: center;
    
}
.loginCard{
    width:420px;
    background-size: cover;
    padding:20px 40px;
    background-image: url(../../Assets/BlackredBackground.png);
    background-repeat: no-repeat;
    border:3px solid #ccc;
    border-radius:20px;
    margin-top:100px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.9);
    z-index: 0;
    padding-bottom: 30px;
}
.loginCard img{
    width:110px;
    margin-top:20px;
}
.login-wrapper .loginCard h1{
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; /* Condensed style */
 font-size: 23px;
 color:#fff;
 margin:15px 0;
}
.loginCard form{
    width:100%;
    margin-bottom: 20px;
}
.loginCard form input{
    width:100%;
    border-radius:15px;
    margin-bottom: 15px;
}
.loginCard .btn-grad{
    width: 50%;
    
}

.loginCard h2{
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; /* Condensed style */
 font-size: 25px;
 color:#fff;
 margin-bottom: 10px;
   
}
.loginCard .loginNavLink{
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; /* Condensed style */
 font-size: 23px;
 color:#fff;
 text-decoration: none;
}

.toFlexDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
}

.toFlexDiv .recaptcha-container {
flex-basis: 40%;
  transform: scale(0.70); /* Adjust the scale to reduce height */
  transform-origin: left; /* Ensure scaling is from the left */
   /* Optional: Add some space between the ReCAPTCHA and the button */
   margin-left: 4px;
}

.toMakeColumn{
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:7px;
}

.toMakeColumn .btn-grad {
  margin-left: -120px;
  width:100px;
  height:36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
}
.toMakeColumn p{
  color: #fff;
  text-align: center;
  width:200px;
  margin-left: -125px;
  font-family: Bahnschrift Condensed;
  font-size: 16px;
}

.custom-google-button {
  font-size: 16px;
  color: #fff;
  background-color: #4285f4;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-google-button:hover {
  background-color: #357ae8;
}

.custom-google-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}



.google-login-wrapper {
  width: 200px;  /* Makes the Google button full width */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  padding-left: auto;
  padding-right: auto;
}

.google-login-wrapper > div {
  width: 50%; 
}

.login-form-footer{
  display: flex;
  gap:10px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px){
  .google-login-wrapper {
    width: 120px;  /* Makes the Google button full width */
    margin-bottom: 20px;
   }
  .login-wrapper{
    overflow-x: hidden;
  }
  .loginCard{
    width:340px;
    padding:20px 20px;
    margin-top:50px;
}
.loginCard img{
    width:120px;
    margin-top:20px;
}
.login-wrapper .loginCard h1{
   font-size: 24px;
 margin:15px 0;
}
.loginCard .btn-grad{
    width: 50%;
    
}

.loginCard h2{
 font-size: 23px;
}
.loginCard .loginNavLink{
 font-size: 23px;
 }

.toFlexDiv .recaptcha-container {
flex-basis: 30%;
  transform: scale(0.60); /* Adjust the scale to reduce height */
   margin-left: 4px;
}


.loginCard form{
  margin-bottom: 10px;
}
.toFlexDiv{
  margin-top:-20px;
  margin-bottom: 10px;
}







.toMakeColumn{
  flex-basis: 60%;
  gap:7px;
}

.toMakeColumn .btn-grad {
  margin-left: -150px;
  width:90px;
  height:32px;
  font-size: 16px;
}
.toMakeColumn p{
  color: #fff;
  text-align: center;
  width:200px;
  margin-left: -160px;
  font-family: Bahnschrift Condensed;
  font-size: 14px;
}
}

