@import url('https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap');

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
}



@font-face {
  font-family: 'UfcSansRegular';
  src: url('../../Fonts/UFCSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-RegularOblique.woff2') format('woff2');
  font-weight: normal;
  font-style: oblique;
}




.createAccount{
    min-height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
background-color: black;  
padding-top:30px;

  
}
.registerCard{
    flex-basis: 50%;
    padding: 20px;
    padding-left: 50px;
    color:#ccc;
    font-family: 'Trebuchet MS', sans-serif;

    margin-top: 120px;
margin-bottom: 100px;   
}
.input-group span{
color:red;
margin-left: 8px;

}
.input-group label{
  margin-bottom: 10px;
}
.registerCard h1{
font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
font-stretch: condensed; /* Condensed style */
margin-bottom: 30px;
text-transform: uppercase;
letter-spacing: 0.5px;
font-size: 45px;
line-height: 2.5rem;
color: #fff;
text-shadow: 2px 4px 8px rgba(0, 0, 0, .55);
}
.input-wrap-one{
    width:100%;
    display: flex;
    gap:20px;
    margin-bottom: 15px;
}
.input-wrap-one .input-group{
flex-basis: 50%;
    display: flex;
    flex-direction: column;

}
.input-wrap-one .input-group input{
    width: 100%;
    box-sizing: border-box;
}


input{
  padding: 10px;
  border: 3px solid #ccc;
  border-radius: 3px;
}








.input-wrap-two{
    width:100%;
    
    display: flex;
    align-items: center;
    gap:20px;
    margin-bottom: 15px;;
}
.input-wrap-two .input-group{
flex-basis: 90%;
    display: flex;
    flex-direction: column;

}
.input-wrap-two .input-group input{
    width: 100%;
    box-sizing: border-box;
}



.checking {
    display: flex;
    align-items: center;
    background-color: #eee;
    margin-bottom: 15px;
    padding: 10px;
    border: 3px solid #ccc;
    border-radius: 3px;
    color:#333;
    font-weight: 700;
  }
  
  .custom-radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .custom-radio-label input {
    display: none;
  }
  
  .custom-radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #777;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: border-color 0.2s;
    background-color: #fff;
  }
  
  .custom-radio.checked {
    border-color: green;
  }
  
  .custom-radio.checked:after {
    content: '\f00c'; /* Font Awesome check mark */
    font-family: FontAwesome;
    color: green;
    font-size: 12px;
  }
  


.backgrounfImg{
    flex-basis: 50%;
    margin-top:-280px;
  
  }
.backgrounfImg img{
    width: 80%;
}
  



.fa-refresh{
  margin-top:24px;
  margin-left: 6px;
  font-size: 29px;
  font-weight: 100;
  
}


.termsConditions{
  margin-top:27px;
  margin-bottom: 23px;
  padding: 15px 10px;
  background-color: #fff;
  border: 3px solid #ccc;
  border-radius: 3px;
  color:rgb(69, 65, 65);
  max-height: 20vh;
  overflow: auto;
}
.termsConditions h2{
  margin-bottom: 5px;
}
         
         
         
.btn-grad {
 background-color: #720e0c;
  margin-top: 10px;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  color: white;            
 font-size: 25px;
  border-radius: 10px;
  font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
  font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
  font-stretch: condensed; /* Condensed style */
   
}


.affiliateLink {
  color: #16B4CA;
  position: absolute;
  top: 180px;
  right: 30px;
  font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
  font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
  font-size: 22px;
  cursor: pointer;
  text-align: center;
}

.affiliateLink::before {
  position: absolute;
  top: 1.5px;
  left: -20px;
  content: '→'; /* Unicode for a right arrow */
  color: #fff; /* Set the desired color */
  font-size: 19px; /* Adjust size as needed */
  animation: blinkText 2s steps(1, end) infinite; /* Adjust timing as needed */
 
}
@keyframes blinkText {
  0%, 100% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
}

/* Stop animation on hover */
.affiliateLink:hover {
  animation: none;
  visibility: visible; /* Ensure it's visible when hovered */
}
.affiliateLinkppp{
  color: #fff;
  position: absolute;
  top: 150px;
  right: 110px;
  font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
  font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
  font-size: 22px;
  cursor: pointer;
  text-align: center;
}
 .affiliateCreateAccount{
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(../../Assets/banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }


@media (max-width: 767px){
  
.affiliateLinkppp{
  top: 150px;
  right: 130px;
  font-size: 14px;
 }
.registerCard{
  flex-basis: 100%;
  padding: 20px;
  padding-left:20px;
  margin-top: 200px;
margin-bottom: 100px;   
}

.backgrounfImg{
  flex-basis: 0%;
  display: none;
}

.affiliateLink {
  top: 180px;
  right: 30px;
  font-size: 22px;
  }

  .registerCard h1{
    margin-bottom: 30px;
    letter-spacing: 0.5px;
    font-size: 32px;
    margin-top:15px;
    text-align: center;
    line-height: 2.5rem;
    }
    

 }
