.adminPredictions{
    width: calc(100% - 230px);
    display: flex;
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(../../Assets/banner.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin-left: 230px; /* Leaves 230px space from the left */
    flex-direction: column;
    align-items: center;
    padding-bottom: 70px;
}
.adminPredictions h1{
    font-family: UFCSans, Arial Narrow, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    color:#fff;
    text-transform: uppercase;
    width:inherit;
    text-align: center;
    margin:30px 0;
}
.adminPredictionsHeader{
    width:100%;
    height:40px;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 18%;
}
.adminPredictionsHeader h2{
    
    font-family: UFCSans, Arial Narrow, Arial, sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }
.adminPredictionsHeader .imagesWrapperAdminPredictions{
    position: absolute;
    top:-15px;
    left:70px;
    display: flex;
    gap:10px;
}
.imagesWrapperAdminPredictions .imgToWrap{
    width:70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:3px solid red;
    border-radius: 50%;
}

.imagesWrapperAdminPredictions .imgToWrap:nth-of-type(1){
    border:3px solid blue;
}
.imagesWrapperAdminPredictions .imgToWrap img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}


.actualPredictionsWrapper{
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap:1px;
    margin-top:50px;
    margin-left:45px;
}
.adminPredictionsButtonsWrapper{
    display: flex;
    gap:50px;
}
.ButtonBoxAdmin{
    width:90px;
    height:90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    border:2px solid #ccc;
    cursor: pointer;
    
}
h1.outputBox{
    margin-top:13px;
}
h1.subHeading2{
    text-align: left;
}

.makeBackgroundBlue{
    background-color: blue;
}
.makeBackgroundRed{
    background-color: red;
}
.buttonPrevNextWrap{
    display: flex;
    justify-content: space-between;
    width: 62%
}
.buttonPrevNextWrap .btn-grad{
    width:28%;
}
.finishFight{
    position: absolute;
    top:-9.5px;
    right: 20px;
    font-size: 20px;
    width:12%;
    cursor: pointer;
}


.actualPredictionsWrapper .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid black;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 1000;
    width:300px;
    height:120px;
  }



















  @media (max-width: 767px){
.adminPredictions{
    width: 100%;
    min-height: 100vh;
    margin-left:0; 
    padding-top: 80px;
}
.adminPredictions h1{
    font-size: 18px;
    width:inherit;
    margin:30px 0;
}
.adminPredictionsHeader{
    width:100%;
    height:35px;
    padding-left: 34%;
}
.adminPredictionsHeader h2{
    
    font-size: 15px;
   }
.adminPredictionsHeader .imagesWrapperAdminPredictions{
    top:-10px;
    left:10px;
    display: flex;
    gap:5px;
}
.imagesWrapperAdminPredictions .imgToWrap{
    width:50px;
    height: 50px;
   }


.actualPredictionsWrapper{
    margin-left:0;
    padding-left: 20px;
    width: 100%;
    overflow: hidden;
    margin-top:15px;
 
}
.adminPredictionsButtonsWrapper{
    gap:15px;
}
.ButtonBoxAdmin{
    width:50px;
    height:50px;
}
h1.outputBox{
    margin-top:13px;
}
h1.subHeading2{
    text-align: left;
}
.buttonPrevNextWrap{
    display: flex;
    justify-content: space-between;
    width: 90%
}
.buttonPrevNextWrap .btn-grad{
    width:28%;
}
.finishFight{
    top:-11.5px;
    right: 5px;
    font-size: 19px;
    width:28%;
   }

.actualPredictionsWrapper .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid black;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 1000;
    width:300px;
    height:120px;
  }

  }
  

  
.adminPredictions .popupPredictions{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(../../Assets//BlackredBackground.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 1000;
    width:450px;
    height:200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    gap:30px;
}
.popupPredictions form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.popupPredictions form input{
    width: 100%;
}
.popupPredictions form button{
    padding: 8px 20px;
    color:#fff;
    background-color: gray;
    border: none;
    outline: none;
    margin-top: 20px;
    border-radius: 5px;
}


.adminPredictions .popup {

    width:400px;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap:20px;
    
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.5);
}

.adminPredictions .popup button{
    width:70px;
    height:70px;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: blue;
    color: #ccc;
    border:4px solid #ccc;
    cursor: pointer;
}
.adminPredictions .popup button:nth-of-type(2){
background-color: red;
}