
.react-calendar {
    width: 80%;
    height: auto;
    max-width: 1200px; /* Set a maximum width for larger screens */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Give it a subtle shadow */
    border-radius: 10px; /* Add some rounded corners */
    border: none; /* Remove any default borders */
}

.react-calendar__tile {
    height: 100px; /* Increase tile size */
    display: flex;
    justify-content: center;
    align-items: center;
}

.highlighted-date {
    background-color: #ffc107;
    border-radius: 50%;
    color: white;
    
}

@media (max-width: 768px) {
    .react-calendar {
        width: 100%; /* Full width on mobile devices */
    }
}

/* Match Item */
.match-item {
    cursor: pointer;
    margin: 10px 0;
}
/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker background for a cleaner look */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    width: 90%;
    max-width: 700px;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Softer, larger shadow */
    text-align: center; /* Center-align content */
    animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in animation */
}

.modal-content img {
    width: 120px;
    height: auto;
    margin: 10px;
    border-radius: 10px; /* Add rounded corners to images */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow to images */
}

.close {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
}

.close:hover {
    color: #ff0000; /* Red color on hover */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Match Item */
.match-item {
    cursor: pointer;
    margin: 10px 0;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.match-item:hover {
    background-color: #f0f0f0;
    transform: translateY(-5px); /* Slight lift on hover */
}
