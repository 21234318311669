.thankyou-purchase-tokens-parent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(../../Assets/backgroundupdated.png);
    background-size: cover;
    background-position: center;
    gap:20px;
    background-repeat: no-repeat;
    min-height: 100vh;
}
.thankyou-purchase-tokens-child{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0,0,0,0.7);
    gap:20px;
    background: #fff;
    width:100%;
    max-width: 600px;
    padding:80px 0;
  
}
.thankyou-purchase-tokens-child h1{
    color: #d20a0a;
    font-size: 28px;
    text-align: center;
    font-family: 'UfcSansRegular';
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    margin-bottom: 5px;
}
.thankyou-purchase-tokens-child h2{
    color: #000;
    font-size: 20px;
    text-align: center;
    font-family: 'UfcSansRegular';
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    word-spacing: 1px;
    position: relative;
    margin-bottom: 5px;
}