.myprofile{
    display: flex;
    background-image: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)),url('../../Assets/new-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    flex-direction: column;
    
   
}
.divTwoProfile{
    flex-basis: 50%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    margin-top:-70px;
}
.profile-btn{
    background-color: black;
    border:2px solid #fff;
    width:60%;
}
.divTwoProfile h1{
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; /* Condensed style */
    font-size:20px;
    margin:10px 0;
    color: #eee;
    text-transform:uppercase;
}
.pairOfHtags{
    margin:25px 0;
}
.input-group-profile{
    display: flex;
    flex-direction: column;
    
}
.input-group-profile label{
    color:#eee;
    margin-bottom:5px;
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-style:normal; 
}
.input-group-profile input{
    background: black;
}

.myprofile .btn-grad{
    width: 30%;
}


.myprofile .createAccount{
    margin-top: -150px;
}
@media (max-width: 767px){
   
.myprofile .createAccount{
    margin-top: -90px;
    flex-direction: column;
}
   
    .divTwoProfile{
        flex-basis: 100%;
        padding-left: 1%;
        gap:10px;
        margin-top:10px;
    }
    .divTwoProfile h1{
        font-size:16px;
        margin:10px 0;
        }
        
    .myprofile .btn-grad{
        width:100% !important;
    }
    .profile-btn{
        width:250px !important;
    }
    
.pairOfHtags{
    width: 300px;
    margin:25px 0;
    margin-left: 0;
    overflow: auto;

}
    
}






