@import url('https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.fightLeaderboardUpdated .fightDetails{
    background-image: url(../../Assets/newFightDetails/nine.jpg);

}

.totalPointsUpdatedTwo{
    margin:50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}.totalPointsUpdatedTwo h1 {
    padding: 5px;
    margin-bottom: 25px;

    /* Adjusted box-shadow to only be at the bottom */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 
                0 15px 25px rgba(255, 69, 0, 0.5); /* Fiery glow focused on the bottom */
    
    width: 28%;
    font-family: 'Saira Stencil One', sans-serif;
    font-size: 56px;
    color: #fff;
    text-shadow: 2px 2px 4px #000; /* Right bottom shadow */
    text-transform: capitalize;
    border-radius: 5px;
    letter-spacing: 1px;
    /* Adjusted background gradient with reduced opacity */
    background: linear-gradient(90deg, rgba(230, 81, 0, 0.3), rgba(255, 152, 0, 0.3), rgba(255, 87, 34, 0.3)); 
    background-clip: padding-box;
}


.totalPointsUpdatedTwo h2{
    font-size: 16px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    letter-spacing: 3px;
   
}
.fightLeaderboardUpdatedTwoParent{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 80vh;
    position: relative;
}
.imgWrapFightsUpdatedTwo{
    width:150px;
    height:150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border:5px solid #fff;
    position: relative;
}

.imgWrapFightsUpdatedTwo img{
    width:100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    
}
.imgWrapFightsUpdatedTwo h1{
    padding: 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 
                0 15px 25px rgba(255, 69, 0, 0.5); 
    font-family: 'Saira Stencil One', sans-serif;
    font-size: 25px;
    font-weight: 100;
    color: #fff;
    text-shadow: 2px 2px 4px #000; 
    text-transform: capitalize;
    border-radius: 5px;
    background: linear-gradient(180deg, rgba(255, 69, 0, 0.4), rgba(255, 165, 0, 0.4), rgba(255, 110, 0, 0.4)); /* Fiery gradient with opacity */
    background-clip: padding-box;
    width:250px;
    position: absolute;
    bottom: -70px;
}

.fightLeaderboardUpdatedTwoParent .itemImgOne {
    position: absolute;
    left: 120px;
    top: 40%; /* Center vertically */
    transform: translateY(-40%); /* Adjust position to the center */
}

.fightLeaderboardUpdatedTwoParent .itemImgTwo {
    position: absolute;
    right: 120px;
    top: 40%; /* Center vertically */
    transform: translateY(-40%); /* Adjust position to the center */
}
.fightLeaderboardUpdatedTwoParent .itemThree{
    position: absolute;
    bottom: 30px;
    left:50%;
    transform: translateX(-50%);
}
.itemThree h1{
    font-family: 'Saira Stencil One', sans-serif;
    font-size: 56px;
    color: #fff;
    text-shadow: 2px 2px 4px #000;
    font-style: italic; 
   
}

.leaderboardItemUpdated{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    padding: 15px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5), /* Subtle shadow */
                0 15px 25px rgba(255, 69, 0, 0.6); /* Fiery glow */
                background: linear-gradient(180deg, rgba(255, 69, 0, 0.4), rgba(255, 165, 0, 0.4), rgba(255, 110, 0, 0.4)); /* Fiery gradient with opacity */
                background-blend-mode: overlay; /* Ensures a smooth blend */


}

.leaderboardItemUpdated h1{
    font-family: 'Saira Stencil One', sans-serif;
    font-size: 24px;
    font-weight: 100;
    color: #fff;
    text-shadow: 2px 2px 4px #000; /* Right bottom shadow */
    text-transform: capitalize;
    letter-spacing: 1px;
   
}

.leaderboardItemUpdated img{
    width: 70px;
    border-radius: 5px;
}



.leaderboardItemsWrapUpdatedTwo{
    height: 35vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.videoWrapperUpdatedTwo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.videoWrapperUpdatedTwo iframe{
  width:90%;
    max-width: 550px;
   height: 300px;
}

@media (max-width: 767px){
    .totalPointsUpdatedTwo h1 {
        padding: 5px;
        width: 80%;
        font-size: 36px;
      }

      .imgWrapFightsUpdatedTwo{
        width:100px;
        height:100px;
        border:3px solid #fff;
       }
    
.imgWrapFightsUpdatedTwo h1{
    font-size: 17px;
    width:150px;
    bottom: -50px;
}

.fightLeaderboardUpdatedTwoParent .itemImgOne {
    position: absolute;
    left: 30px;
    top: 70px; /* Center vertically */
}

.fightLeaderboardUpdatedTwoParent .itemImgTwo {
    position: absolute;
    right: 30px;
    top: 70px;
}


.leaderboardItemUpdated{
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    

}

.leaderboardItemUpdated h1{
    font-size: 17px;
    margin-left: 15px;
   
}

.leaderboardItemUpdated img{
    width: 60px;
   
}
      
   

.leaderboardItemsWrapUpdatedTwo{
    margin-top:120px;
    height: 35vh;
    align-items: flex-start;
    justify-content: flex-start;
   }

}
