.visitorsAnalyticsWrapper {
    

    width: calc(100% - 230px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(../../Assets/backgroundupdated.png);
    background-size: cover;
    background-position: center;
    gap:20px;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin-left: 230px; /* Leaves 230px space from the left */
  }
  .visitorwrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 20px rgba(0,0,0,0.7);
    gap:20px;
    background: #fff;
    width:100%;
    max-width: 600px;
    padding:50px 0;
  }
  .resetStatsBtn {
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;


    font-family: 'UfcSansRegular';
    background: linear-gradient(216.01deg, rgb(210, 10, 10) 26.18%, rgb(158, 8, 8) 93.27%);
  }
  .visitorwrapper h1{
    color: #d20a0a;
    font-size: 28px;
    text-align: center;
    font-family: 'UfcSansRegular';
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    margin-bottom: 5px;
   
  }
  .graphPlaceholder {
    width: 100%;
    height: 300px;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }
  
  .filterOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .filters {
    display: flex;
    gap: 10px;
  }
  
  .filterBtn {
    background-color: #2196f3;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .filterBtn.active {
    background-color: #0d47a1;
  }
  
  .filteredStats {
    font-size: 18px;
    font-weight: bold;
  }
  .visitorwrapper h3{
    color: #481b38;
    font-size: 25px;
    text-align: center;
    font-family: 'UfcSansRegular';
    font-weight: 600;
    position: relative;
   
  }
  .visitorwrapper h2{
    color: #000;
    font-size: 24px;
    text-align: center;
    font-family: 'UfcSansRegular';
    font-weight: 600;
    position: relative;
   
  }


  @media (max-width: 767px){
    .visitorsAnalyticsWrapper {
      width: 100%;
      margin-left: 0; /* Leaves 230px space from the left */
    }
  
    .visitorwrapper{
      width:90%;
      padding:50px 10px;
      }
  }