@import url('https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap');

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
}



@font-face {
  font-family: 'UfcSansRegular';
  src: url('../../Fonts/UFCSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UFCSans';
  src: url('../../Fonts/UFCSans-RegularOblique.woff2') format('woff2');
  font-weight: normal;
  font-style: oblique;
}


.uploadAvatar{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../Assets/RING1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    flex-direction: column;
    text-align: center;
}
.uploadAvatar h1{
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500; /* Weight corresponding to UFCSans-CondensedMedium */
    font-stretch: condensed; /* Condensed style */
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 45px;
    line-height: 2.5rem;
    color: #fff;
    text-shadow: 2px 4px 8px rgba(0, 0, 0, .55);
    margin-top:80px;
    
}
.btn-grad{
    width:17%;
    border:2px solid gray;
    padding:5px;
    padding-top:7px;
}
.uploadAvatar img{
  width:200px;
  margin-bottom: 20px;
  border-radius:50%;
}


@media (max-width: 767px){
  .uploadAvatar .btn-grad{
    width:60%;
  }
}