.usersListWrapper {
    width: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-top:70px;
  }
  .usersListWrapper input:nth-of-type(1){
    margin-right: 10px;
  }
  .userListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .usersTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .usersTable th, .usersTable td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .usersTable th {
    background-color: #f1f1f1;
    font-weight: bold;
  }
  
  .userRow:hover {
    background-color: #f0f8ff;
  }
  
  .usersTable input {
    margin-right: 10px;
  }
  .nonregistereduserslist {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    padding: 10px 20px; /* Padding for a bigger clickable area */
    font-size: 16px; /* Increase font size */
    font-weight: bold; /* Bold text */
    border: none; /* Remove default border */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
  }
  
  .nonregistereduserslist:hover {
    background-color: #45a049; /* Darker green on hover */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
    transform: scale(1.05); /* Slight zoom-in effect on hover */
  }
  
  .nonregistereduserslist:active {
    background-color: #3e8e41; /* Even darker green on active (click) */
    transform: scale(0.98); /* Slight zoom-out effect on click */
  }
  

   
@media (max-width: 767px){
  .usersListWrapper {
    width: 90%;
    overflow: auto;
   }
  
   .userListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .usersTable {
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
  }
  
  .usersTable th, .usersTable td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .usersTable th {
    background-color: #f1f1f1;
    font-weight: bold;
  }
  
  .userRow:hover {
    background-color: #f0f8ff;
  }
  
  .usersTable input {
    margin-right: 10px;
  }
  
}











.nonRegisteredUsers h1 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffcc00; /* Gold text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Text shadow */
}

/* General form styling */
.nonRegisteredUsers .user-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.nonRegisteredUsers .user-form input {
  padding: 12px;
  margin: 0 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 220px;
  background: rgba(255, 255, 255, 0.2); /* Transparent background */
  color: #fff; /* White text */
}

.nonRegisteredUsers .user-form input::placeholder {
  color: #ddd; /* Lighter placeholder */
}

.nonRegisteredUsers .user-form button {
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  background-color: #ffcc00; /* Gold */
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nonRegisteredUsers .user-form button:hover {
  background-color: #ffc107; /* Darker gold on hover */
}

/* General table styling */
.nonRegisteredUsers table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
  color: #fff;
}

.nonRegisteredUsers table th, .nonRegisteredUsers table td {
  padding: 15px;
  border: 1px solid #444;
  text-align: center;
  font-size: 16px;
}

.nonRegisteredUsers table th {
  background-color: #ffcc00; /* Gold header */
  color: #000;
  font-weight: bold;
}

.nonRegisteredUsers table tr:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Slight hover effect */
}

.nonRegisteredUsers table td button {
  padding: 8px 12px;
  background-color: #f44336; /* Red button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nonRegisteredUsers table td button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Responsive styles */
@media (max-width: 768px) {
  .nonRegisteredUsers h1 {
    font-size: 28px; /* Smaller font size on mobile */
  }

  .nonRegisteredUsers .user-form {
    flex-direction: column; /* Stack inputs and button */
    align-items: center;
  }

  .nonRegisteredUsers .user-form input {
    width: 90%; /* Take up more space on mobile */
    margin: 10px 0; /* Space between inputs */
  }

  .nonRegisteredUsers .user-form button {
    width: 90%; /* Make the button wider on mobile */
    padding: 10px;
  }

  .nonRegisteredUsers table {
    width: 100%; /* Full width on mobile */
  }

  .nonRegisteredUsers table th, .nonRegisteredUsers table td {
    padding: 10px; /* Reduce padding for smaller screens */
    font-size: 14px; /* Smaller font size for readability */
  }
}

@media (max-width: 480px) {
  .nonRegisteredUsers h1 {
    font-size: 24px; /* Even smaller font for extra small devices */
  }

  .nonRegisteredUsers .user-form input {
    width: 100%; /* Max width on very small screens */
  }

  .nonRegisteredUsers .user-form button {
    width: 100%; /* Full width button on mobile */
  }

  .nonRegisteredUsers table th, .nonRegisteredUsers table td {
    font-size: 12px; /* Smaller text on very small screens */
  }
}
