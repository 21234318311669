.fightItem-leagues {
    display: flex;
    gap: 20px;
    justify-content: left;
    align-items: left;
    padding: 5px;
    width: 350px;
    background-image: linear-gradient( 0.2deg, rgba(117,6,6,1) 12.1%, rgba(86,30,23,1) 52.2%, rgba(66,55,59,1) 93.1% );
    box-shadow: inset 0 10px 15px rgba(0, 0,0,0.1);
    border:4px solid #ccc;
    margin-bottom: 10px;
}

.fightItem-leagues .imgWrapLeague-leagues{
    width:60px;
    height:60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}
.fightItem-leagues .imgWrapLeague-leagues img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.transformed-div-leagues{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    

}
.transformed-div-leagues h1{
    font-size: 17px;
    font-family: 'UFCSans', Arial, sans-serif;;
}

.transformed-div-leagues h2{
    font-size: 15px;
    color:red;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.transformed-div-leagues p{
    font-size: 14px;
    color: #333;
    font-family: 'Courier New', Courier, monospace;
}




/*.myleagues .completedFights .transformed-div {
    background-color: #decf00;
   
}
.myleagues .completedFights .transformed-div h1 {
    color:#188504;
   
}

.leagueFightItem{
    background-color: #0eb41f;
}*/


@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@300;400;700&display=swap');

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
    background-image: linear-gradient( 0.2deg, rgba(117,6,6,1) 12.1%, rgba(86,30,23,1) 52.2%, rgba(66,55,59,1) 93.1% );
  border: 3px solid #ff414d; /* Bold red boxing theme border */
  width: 90%;
  max-width: 600px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  animation: fadeIn 0.3s ease;
}

.popup-content h2 {
  font-family: 'Anton', sans-serif;
  color: #ffcd38; /* Bold golden color */
  font-size: 2em;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.popup-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-content li {
  font-size: 1.2em;
  margin: 10px 0;
  padding: 8px;
  color: #ffcd38;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  font-weight: 400;
}

.popup-content button {
  background-color: #ff414d;
  border: none;
  color: #fff;
  padding: 12px 24px;
  font-size: 1em;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 20px;
}

.popup-content button:hover {
  background-color: #ff6e73;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
