@import url('https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

@font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-CondensedBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-stretch: condensed;
  }
  


  @font-face {
    font-family: 'UfcSansRegular';
    src: url('../../Fonts/UFCSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-RegularOblique.woff2') format('woff2');
    font-weight: normal;
    font-style: oblique;
  }
  
  .no-hover iframe {
    }
  
.homeSecond{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 50vh;
    background-color: black;
    gap:40px;
}
.homeSecond .second-main-heading{
    font-size: 40px;
    line-height: 60px;
    color: #2d1e22;
    text-transform: uppercase;
    text-align: center;
    
    font-family: 'UFCSans', Arial, sans-serif;
  font-weight: bold; /* Ensure bold weight is applied */
  font-style: normal; /* Ensure normal style is applied */
  font-stretch: condensed; /* Ensure condensed style is applied */
  font-style: italic;
    background-color: #ffc000;
    padding: 20px;
    margin-bottom: 10px;
    
   }
.homeSecond .fightswrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap:30px;
   
} 
.fightItem{
    display: flex;
    flex-direction: column;
    gap:5px;
    position: relative;
    
}
.fightItemOne{
    display: flex;
    
}
.transformed-div {
    height: 50px;
    width: 450px;
    background-color: #fff;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    display: flex;
    align-items: center;
    
}
.fightItemOne .transformed-div h1{
    text-transform: uppercase;
    color: #d30c23;
    margin-left: 33%;

    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    

    font-size: 24px;
    }
.transformed-div-two {
    height: 50px;
    width: 450px;
    background-color: #cbcbcb;
    clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
    margin-left: -15px;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    padding-top: 5px;
    gap:1px;
}
.transformed-div-two-partOne{
    display: flex;
    gap: 10px;
}

.transformed-div-two-partOne h1{
    font-size: 21px;
    text-transform: uppercase;
    color: black;

    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    
}
.transformed-div-two-partTwo{
    display: flex;
    gap: 10px;
}

.transformed-div-two-partTwo h1{
    font-size: 17px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 700;
    text-transform: uppercase;
    color: #388218;
}

.transformed-div-two-partTwo p{
    font-size: 17px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 900;
    text-transform: uppercase;
    color: black;
}




.fightItemTwo{
    display: flex;
}
.transformed-div-three {
    height: 46px;
    width: 600px;
    background-color: #c5c5c5;
    margin-right: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    }
    .transformed-div-three p{
        
        font:bold 14px / 15px 'Trebuchet Ms', sans-serif;
        text-shadow: none;
    
    }
    .noMatch{
      font:bold 14px / 15px 'Trebuchet Ms', sans-serif;
      color:#fff;
      font-size: 18px;
    }
    .transformed-div-four {
        height: 46px;
        width: 200px;
        background-color: #c5c5c5;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
    }
    .transformed-div-four h1{
    font-size: 19px;
    font-family: 'UFCSans', Arial, sans-serif;
    font-weight: bold; /* Ensure bold weight is applied */
    font-style: normal; /* Ensure normal style is applied */
    font-stretch: condensed; /* Ensure condensed style is applied */
    
    text-transform: uppercase;
    color: #d30c23;
    }
    .transformed-div-four p{
    font-size: 18px;
    font-family: 'UfcSansRegular', Arial, sans-serif;

    font-weight: 900;
    
    }



.fightItem .fightersImages{
    position: absolute;
    top:-12px;
    left:-17px;
    z-index: 1;
    display: flex;
    gap:2px;
}
.fighterOne{
    width:75px;
    height: 75px;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border:3px solid blue;
    
}
.fighterOne img{
    width:100%;
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
    
    
}


.fighterTwo{
    width:75px;
    height: 75px;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border:3px solid red;

    
}
.fighterTwo img{
    width:100%;
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
    
}


.fightswrap .fightItem:nth-of-type(1) .transformed-div-two {
    background-color: #decf00;
  }
  











  .homeThird{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    background-color: black;
    padding: 70px 0;
  }
  .homeThird .thirdHeadingOne{
    font-size: 32px;
    line-height: 60px;
    color: #c0c0c0;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-text-shadow: 6px 5px 6px #201e1f;
    -moz-text-shadow: 6px 5px 6px #201e1f;
    -o-text-shadow: 6px 5px 6px #201e1f;
    text-shadow: 6px 5px 6px #201e1f;
    text-align: center;
   
  }
  
  .homeThird h2{
    font-size: 28px;
    line-height: 60px;
    color: #c0c0c0;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-text-shadow: 6px 5px 6px #201e1f;
    -moz-text-shadow: 6px 5px 6px #201e1f;
    -o-text-shadow: 6px 5px 6px #201e1f;
    text-shadow: 6px 5px 6px #201e1f;
    text-align: center;
   
  }
  .homeThird h2 span{
    color: #388218;
    font-family: 'UfcSansRegular', Arial, sans-serif;

  }
  .leaderboardHeading{
    width:100%;
    text-align: center;
    border-bottom: 2px solid #c0c0c0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-top:20px;
  }
  .leaderboardHeading h3{
    border-radius: 15px 15px 0 0;
    border:2px solid #c0c0c0;
    width:200px;
    padding:10px;
    background-color: rgb(8, 8, 105);



    font-size: 22px;
    color: #c1ce11;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
   }

  .homeThird .controls{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:25px;
    margin: 20px 0;;
  }
  .homeThird .controls h5{
    font-size: 22px;
    line-height: 60px;
    color: #c0c0c0;
    font-family: 'UFCSans', Arial, sans-serif;
  font-weight: bold; /* Ensure bold weight is applied */
  font-style: normal; /* Ensure normal style is applied */
  font-stretch: condensed; /* Ensure condensed style is applied */
    text-transform: uppercase;
    -webkit-text-shadow: 6px 5px 6px #201e1f;
    -moz-text-shadow: 6px 5px 6px #201e1f;
    -o-text-shadow: 6px 5px 6px #201e1f;
    text-shadow: 6px 5px 6px #201e1f;
    text-align: center;
  }
  .homeThird .controls .active{
    color: #e19500;
  }

  .leaderboardItemsWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:25px;
  }
  .leaderboardItem{
   position: relative;
   height: 50px;
   width: 650px;
   background-color: #c5c5c5;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-radius: 15px;
   border:1px solid gray;
   padding-left: 70px;
   padding-right: 15px;
  }
  .leaderboardItem .leaderboard-item-image{
    width:70px;
    height: 70px;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border:3px solid #fff;
    position: absolute;
    top:-13px;
    left: -10px;;

    
}
.leaderboardItem .leaderboard-item-image img{
    width:100%;
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
    
}
.leaderboardItem h1{
    font-size: 18px;
    color: black;
   
    font-family: 'UfcSansRegular', Arial, sans-serif;

    text-transform: uppercase;
    
  
}
.btn-grad{
  cursor: pointer;
}

@media (max-width: 767px){
  
.homeFirst{
  min-height: 100vh;
  width:100%;
  overflow: hidden;
}
.homeFirst h1{
  font-size: 30px;
  line-height: 60px;
  margin-top:85px;
}
.homeFirst h2{
  font-size: 35px;
  line-height: 59px;
  width: 300px;
  }











  .homeSecond{
    min-height: 40vh;
    gap:40px;
}
.homeSecond .second-main-heading{
    font-size: 38px;
    padding:10px 20px;
    
   }
.homeSecond .fightswrap{
    gap:30px;
    
} 
.transformed-div {
    height: 40px;
    width: 170px;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    
}
.fightItemOne .transformed-div h1{
    margin-left: 45%;
    font-size: 13px;
    }
.transformed-div-two {
    height: 40px;
    width: 170px;
    clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
    margin-left: 0px;
    padding-left: 20px;
    padding-top: 3px;
    gap:1px;
}
.transformed-div-two-partOne{
    display: flex;
    gap: 10px;
}

.transformed-div-two-partOne h1{
    font-size: 17px; 
}
.transformed-div-two-partTwo{
    gap: 10px;
}

.transformed-div-two-partTwo h1{
    font-size: 14px;
  }

.transformed-div-two-partTwo p{
    font-size: 14px;
    font-weight: 900;
   }



.transformed-div-three {
    height: 41px;
    width: 220px;
    margin-right: 10px;
    padding-left: 10px;
    }
    .transformed-div-four {
        height: 41px;
        width: 80px;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        padding-right: 20px;
        margin-left: 0px;
    }
    .transformed-div-four h1{
    font-size: 15px;
    }
    .transformed-div-four p{
    font-size: 14px;
    font-weight: 900;
    }



.fightItem .fightersImages{
    position: absolute;
    top:-4px;
    left:-8px;
    display: flex;
    gap:2px;
}
.fighterOne{
    width:40px;
    height: 40px;
    
}


.fighterTwo{
    width:40px;
    height: 40px;
}














  
  .homeThird{
    min-height: 70vh;
    padding: 70px 0;
  }
  .homeThird .thirdHeadingOne{
    font-size: 26px;
  }
  
  .homeThird h2{
    font-size: 25px;
  }
  .leaderboardHeading{
    margin-top: 15px;
    margin-top:20px;
  }
  .leaderboardHeading h3{
    font-size: 21px;
   }

  .leaderboardItemsWrap{
    gap:27px;
  }
  .leaderboardItem{
   height: 45px;
   width: 320px;
   padding-left: 70px;
   padding-right: 15px;
  }

.leaderboardItem h1{
    font-size: 16px;
   }

.toRemove{
  display: none;
}
}







.homeFourth{
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../Assets/RING2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
}

.video-embed-wrapper {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto; /* Center the video on the page */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;
}

.video-embed-wrapper iframe {
  width: 100%;
  height: 450px; /* Adjust the height as needed */
  border-radius: 8px; /* Optional: rounded corners on the iframe */
}














	
.faq-section {
  background: transparent;
  min-height: 75vh;
  padding: 10vh 0 0;
padding-bottom:0px;
background-color: #000000;
}
.faq-title h2 {
position: relative;
margin-bottom: 45px;
display: inline-block;
font-weight: 600;
line-height: 1;
color:#fff;
}
.faq-title h2::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 60px;
  height: 2px;
  background: #E91E63;
  bottom: -25px;
  margin-left: -30px;
}
#accordion{
max-width:50%;
}
.faq-title p {
padding: 0 190px;
margin-bottom: 10px;
}
.faqsss{
display:flex;
justify-content:center;
align-items:center;
}
.faq {
background: transparent;
box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
border-radius: 4px;
}

.faq .card {
border: none;
background: none;

}

.faq .card .card-header {
padding: 0px;
border: none;
background: red;
-webkit-transition: all 0.3s ease 0s;
-moz-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
margin-bottom:20px;
clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
   

}

.faq .card .card-header:hover {
color:#fff;  padding-left: 10px;
}
.faq .card .card-header .faq-title {
width: 100%;
text-align: left;
padding: 0px;
padding-left: 30px;
padding-right: 30px;
font-weight: 400;
font-size: 17.5px;
letter-spacing: 1px;
color: #d2d2d2;
text-decoration: none !important;
-webkit-transition: all 0.3s ease 0s;
-moz-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
cursor: pointer;
padding-top: 20px;
padding-bottom: 20px;

font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.faq .card .card-header .faq-title .badge {
display: inline-block;
width: 20px;
height: 20px;
line-height: 21px;
float: left;
-webkit-border-radius: 100px;
-moz-border-radius: 100px;
border-radius: 100px;
text-align: center;
background: #fff;
color: #000000;
font-size: 12px;
margin-right: 20px;
margin-top:1.5px;
}

.faq .card .card-body {
padding: 30px;
padding-left: 35px;
padding-bottom: 16px;
font-weight: 400;
font-size: 16px;
color: #6F8BA4;
line-height: 28px;
letter-spacing: 1px;
border-top: 1px solid #F3F8FF;
}

.faq .card .card-body p {
margin-bottom: 14px;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.faq .card .collapse.show {
  display: block;
}

.faq .card .collapse {
  display: none;
}


@media (max-width: 991px) {
.faq {
  margin-bottom: 30px;
}
.faq .card .card-header .faq-title {
  line-height: 26px;
  margin-top: 10px;
}
}

@import url('https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

@font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-CondensedBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-CondensedMedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-stretch: condensed;
  }
  


  @font-face {
    font-family: 'UfcSansRegular';
    src: url('../../Fonts/UFCSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'UFCSans';
    src: url('../../Fonts/UFCSans-RegularOblique.woff2') format('woff2');
    font-weight: normal;
    font-style: oblique;
  }
  

.homeFirst{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("../../Assets/boxing-victory.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  padding-top: 170px;
  padding-bottom: 70px;

}
.homeFirst h1{
    font-size: 60px;
    line-height: 60px;
    color: #fff;
    font-family: 'Bungee', sans-serif;
    text-transform: uppercase;
    text-align: center;
    margin-top:85px;
}
.homeFirst h2{
    color: #d30c23;
    font-size: 60px;
    line-height: 79px;
    text-align: center;
    padding: 27px 0;
    width: 1120px;
    font-family: 'Bungee', sans-serif;
    text-transform: uppercase;
    -webkit-text-shadow: 6px 5px 6px #201e1f;
    -moz-text-shadow: 6px 5px 6px #201e1f;
    -o-text-shadow: 6px 5px 6px #201e1f;
    text-shadow: 6px 5px 6px #201e1f;
    margin-bottom: 100px;
}







.homeFourth{
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../Assets/RING2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
}

.video-embed-wrapper {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto; /* Center the video on the page */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  text-align: center;
}

.video-embed-wrapper iframe {
  width: 100%;
  height: 450px; /* Adjust the height as needed */
  border-radius: 8px; /* Optional: rounded corners on the iframe */
}









@media (max-width: 767px){
  
  .homeFirst{
    
    min-height: 100vh;
    width:100%;
    overflow: hidden;
    padding-top:140px;
  }
  .homeFirst h1{
    font-size: 30px;
    line-height: 60px;
    margin-top:85px;
  }
  .homeFirst h2{
    font-size: 35px;
    line-height: 59px;
    width: 300px;
    margin-bottom: 140px;
    }
  


    
.video-embed-wrapper {
  margin:0; /* Center the video on the page */
  border-radius: 10px; /* Rounded corners */
 }

.video-embed-wrapper iframe {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  border-radius: 8px; /* Optional: rounded corners on the iframe */
}

  }  


.thirdHomePageSection{
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  min-height: 75vh;
  background-image: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(../../Assets/homeThirdBackground.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding:20px 0;
}
.thirdHomePageSection .moneyone{
  width:100px;
  position: absolute;
  top:10%;
  right:15%;
  transform: rotate(30deg);
}

.thirdHomePageSection .moneytwo{
  width:100px;
  position: absolute;
  top:50%;
  left:5%;
}
.thirdHomePageSection h1{
  font-size: 50px;
    line-height: 60px;
    color: #fff;
    font-family: 'Bungee', sans-serif;
    text-transform: uppercase;
    text-align: center;
    -webkit-text-shadow: 6px 5px 6px #201e1f;
    -moz-text-shadow: 6px 5px 6px #201e1f;
    -o-text-shadow: 6px 5px 6px #201e1f;
    text-shadow: 6px 5px 6px #201e1f;
   
}


.thirdHomePageSection h2{
  color: #d30c23;
    font-size:50px;
    line-height: 79px;
    text-align: center;
    padding: 27px 0;
    width: 1120px;
    font-family: 'Bungee', sans-serif;
    text-transform: uppercase;
    -webkit-text-shadow: 6px 5px 6px #201e1f;
    -moz-text-shadow: 6px 5px 6px #201e1f;
    -o-text-shadow: 6px 5px 6px #201e1f;
    text-shadow: 6px 5px 6px #201e1f;
   
}
.thirdHomePageSection p{
  text-align: center;
  color: #fff;
  font-family: 'UfcSansRegular';
  font-size: 24px;}

  .thirdHomePageSection button{
    padding: 14px 30px;
    font-size: 22px;
    font-weight: bold;
    color: #fff; /* White text */
    background: linear-gradient(45deg, #128C7E, #075E54, #25D366, #34B7F1); /* WhatsApp gradient */
    background-size: 300% 300%; /* Ensures smooth gradient transitions */
    border: none;
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    cursor: pointer;
    transition: background-position 0.5s ease, box-shadow 0.3s ease, transform 0.3s ease;
    animation: gradientAnimation 5s ease infinite; /* Gradient animation */
    z-index: 9;
    margin-top: 20px;  
  }

  /* General media query for small devices */
@media (max-width: 768px) {
  .thirdHomePageSection {
    padding: 10px 0;
    min-height: 60vh; /* Adjust height for smaller screens */
   
  }

  .thirdHomePageSection .moneyone,
  .thirdHomePageSection .moneytwo {
   display: none;
  }


  .thirdHomePageSection h1 {
    font-size: 32px; /* Reduce heading size */
    line-height: 40px;
  }

  .thirdHomePageSection h2 {
    font-size: 32px; /* Reduce subheading size */
    line-height: 50px;
    width: 90%; /* Reduce width for smaller screens */
    padding: 20px 0;
  }

  .thirdHomePageSection p {
    font-size: 16px; /* Adjust paragraph font size */
    padding: 10px;
  }
}

/* Media query for extra-small devices */
@media (max-width: 480px) {
  .thirdHomePageSection {
    min-height: 50vh;
  }

  .thirdHomePageSection h1 {
    font-size: 24px; /* Further reduce heading size */
    line-height: 30px;
  }

  .thirdHomePageSection h2 {
    font-size: 24px; /* Further reduce subheading size */
    line-height: 35px;
    padding: 15px 0;
  }

  .thirdHomePageSection p {
    font-size: 14px; /* Smaller font size for paragraphs */
  }

  .thirdHomePageSection .moneyone,
  .thirdHomePageSection .moneytwo {
   display: none;
  }

}
