/* Overlay that dims the background */
.modal-overlay-instructions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal appears above all content */
}

/* Modal content box */
.modal-overlay-instructions .modal-content {
  background-color: #FFF;
  padding: 30px;
  width: 90%; /* Adjust width for better mobile responsiveness */
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1001; /* To ensure it's above the overlay */
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Modal heading */
.modal-overlay-instructions .modal-content h2 {
  font-size: 24px;
  color: #FF4500; /* Matching theme color */
  margin-top: 0;
}

/* Modal paragraph and list */
.modal-overlay-instructions .modal-content p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.modal-overlay-instructions .modal-content ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.modal-overlay-instructions .modal-content ul li {
  margin-bottom: 10px;
  color: #555;
}

/* Close button */
.modal-overlay-instructions .close-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #FF4500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-overlay-instructions .close-btn:hover {
  background-color: #e04300;
  transition: background-color 0.3s ease;
}

/* MEDIA QUERIES for responsiveness */
@media (max-width: 768px) {
  .modal-overlay-instructions .modal-content {
    width: 95%; /* Smaller width on tablets */
    padding: 20px; /* Reduce padding */
    max-width: 600px; /* Max width for tablet devices */
  }
  
  .modal-overlay-instructions .modal-content h2 {
    font-size: 22px; /* Slightly smaller heading */
  }
  
  .modal-overlay-instructions .modal-content p,
  .modal-overlay-instructions .modal-content ul {
    font-size: 14px; /* Adjust font size for better readability */
  }
}

@media (max-width: 480px) {
  .modal-overlay-instructions .modal-content {
    width: 100%; /* Full width on small mobile screens */
    padding: 15px; /* Reduce padding for mobile */
    max-width: 100%; /* No max-width for small screens */
  }
  
  .modal-overlay-instructions .modal-content h2 {
    font-size: 20px; /* Smaller heading for mobile */
  }
  
  .modal-overlay-instructions .modal-content p,
  .modal-overlay-instructions .modal-content ul {
    font-size: 13px; /* Smaller font size for content on mobile */
  }
  
  .modal-overlay-instructions .close-btn {
    font-size: 14px; /* Smaller close button for mobile */
    padding: 8px 16px;
  }
}




.videoRecorderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.statusText {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.recordedVideo {
  width: 100%;
  max-width: 600px; /* Maximum width for larger screens */
  height: auto;
  border: 2px solid #38b90c; /* Green border to enhance visibility */
  border-radius: 8px; /* Rounded corners */
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin-top: 40px;
  gap:20px;
}

.recordButton,
.stopButton,
.saveButton {
  background-color: #38b90c; /* Attractive green color */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Padding for buttons */
  margin: 5px 0; /* Space between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.recordButton:hover {
  background-color: #32a80a; /* Darker green on hover */
}

.stopButton {
  background-color: #d9534f; /* Attractive red color for stop */
}

.stopButton:hover {
  background-color: #c9302c; /* Darker red on hover */
}

.saveButton {
  background-color: #5bc0de; /* Attractive blue color for save */
}

.saveButton:hover {
  background-color: #31b0d5; /* Darker blue on hover */
}
