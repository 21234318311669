.imgWrapLeague{
    z-index: 1 !important;
}
.fightsWrap{
    z-index: 0 !important;
}
.fightItem{
    z-index: 0 !important;
}
.admin-header{
    z-index: 99999 !important;
}

.home-arrow-circle{
    position: absolute;
    top: 127px;
    left: 70px;
    cursor: pointer;
    font-size: 24px;
     color: #007bff;
}
.home-arrow-circle-forum{
  left:30px;
  top:147px;
}
.homeup-arrow-circle{
    position: absolute;
    top: 127px;
    left: 70px;
    cursor: pointer;
    font-size: 24px;
     color: #007bff;
}

.dashboard-arrow-circle{
    position: absolute;
    top: 127px;
    left: 35px;
    cursor: pointer;
    font-size: 24px;
     color: #007bff;
     z-index: 999999;
}
.homecreateacc-arrow-circle{
 
    position: absolute;
    top: 97px;
    left: 70px;
    cursor: pointer;
    font-size: 24px;
     color: #007bff;   
}

    .fightItemOne .transformed-div h1{
      padding-right: 10px ;
      font-size: 18px !important ;
     
     }

     
.fightItemOne .transformed-div .transformedFighterNames{
    font-size: 15px !important;
}
.playpause{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width:40px;
  height:40px;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 767px){
    
    .fightItemOne .transformed-div .transformedFighterNames{
        font-size: 8px !important;
        font-family:  system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    }    
    .home-arrow-circle{
        top: 107px;
     }
     
.home-arrow-circle-forum{
  left:30px;
  top:142px;
}
     .homeup-arrow-circle{
        top: 90px;
     }
     
.homecreateacc-arrow-circle{
 
    top: 37px;
    left: 20px;
    font-size: 20px;
  }
  .loginbackarrow{
    top:100px;
  }

  .dashboard-arrow-circle{
    top: 37px;
    left: 17px;
    font-size: 21px;
    z-index: 1;
   }
}
/* Portrait orientation */
@media only screen and (device-width: 414px) and (device-height: 896px) and (orientation: portrait) {
    .upcomingFights .transformed-div-two-partOne h1 , .pendingFights .transformed-div-two-partOne
    , .completedFights .transformed-div-two-partOne {
      margin-top: -3px;
    }
    
    .upcomingFights .transformed-div-two-partTwo,  .pendingFights .transformed-div-two-partTwo 
    ,  .completedFights .transformed-div-two-partTwo  {
      margin-top: -8px;
    }
  }
  
  /* Landscape orientation */
  @media only screen and (device-width: 896px) and (device-height: 414px) and (orientation: landscape) {
    .upcomingFights .transformed-div-two-partOne h1 , .pendingFights .transformed-div-two-partOne{
      margin-top: -3px;
    }
    
    .upcomingFights .transformed-div-two-partTwo,  .pendingFights .transformed-div-two-partTwo {
      margin-top: -3px;
    }
  }
  


  .videoContainer {
    margin-top: 20px;
    overflow: hidden;
  }
  
  .responsiveVideo {
    width: 100%;
    max-width:600px;
    height: auto;
  }
  