.contactWrapper h2{
    margin-bottom: 1px;
}
.contactWrapper form{
    width:100%;
    
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap:15px;
}
.contactWrapper form input, .contactWrapper form textarea{
    background-color: #111111;
    border-width: 1px;
    border-style: solid;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #ffffff;
    font-family: Bungee;
    font-size: 17px;
    line-height: 44px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: uppercase;
    padding-top: 12px;
    padding-right: 31px;
    padding-bottom: 12px;
    padding-left: 31px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.contactWrapper form button{
    width:55%;
    background-color: #de4039;
    border-width: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #ffffff;
    font-family: Bungee;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: uppercase;
    height: 64px;
    line-height: 64px;
    padding: 0 80px;
}