.addNewMatch{
    width: calc(100% - 230px);
    display: flex;
    justify-content: center;
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(../../Assets/banner.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin-left: 230px; /* Leaves 230px space from the left */
}
.addNewMatch h1{
    text-align: center;
}
.addNewMatch select{
    padding: 10px;
    border: 3px solid #ccc;
    border-radius: 3px;
  
}
.checkBox{
    margin:5px 0;
}
.checkBox input{
    width:20px;
    height: 20px;
}





.specialDivInputs{
    margin-top: 30px;
}
.special-input-group{
    display: flex;
    justify-content: center;
    align-items: center;
}
.special-input-group img{
    border-radius: 50%;
    border:3px solid #ccc;
    width: 150px;
}

@media (max-width: 767px){
    .addNewMatch{
        width: 100%;
        min-height: 100vh;
        margin-left: 0;
        align-items: center;
        
    }
    .addNewMatch h1{
        text-align: center;
    }
    .addNewMatch select{
        padding: 10px;
        border: 3px solid #ccc;
        border-radius: 3px;
      
    }
    .checkBox{
        margin:5px 0;
    }
    .checkBox input{
        width:20px;
        height: 20px;
    }
    
    
}


.addNewMatch .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1000; /* Ensure it's on top of other content */
    text-align: center;
    width:400px;
    height:200px;
  }
  
  .addNewMatch .popup h2 {
    margin-bottom: 15px;
  }
  
  .addNewMatch .popup button {
    margin: 0 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .addNewMatch .popup button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .addNewMatch .popup button:not(:last-child) {
    margin-right: 15px; /* Spacing between buttons */
  }
  


.custom-file-label {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #e60000; /* Professional red background */
    font-weight: 600;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-file-label:hover {
    background-color: #cc0000; /* Darker red on hover */
  }
  
  .custom-file-label:active {
    background-color: #b30000; /* Even darker red on click */
  }







  .addNewMatch .toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  .addNewMatch .toggle-switch input {
    display: none;
  }
  
  .addNewMatch .switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 20px;
    transition: 0.4s;
  }
  
  .addNewMatch .switch::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
  
  .addNewMatch input:checked + .switch {
    background-color: #4caf50;
  }
  
  .addNewMatch input:checked + .switch::before {
    transform: translateX(20px);
  }
  