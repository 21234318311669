.youtubeLibrary{
    flex-direction: column;
    gap:30px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url(../../Assets/banner.png);
  }
.youtubeLibrary h1{
    font-family: 'UFCSans', "Arial Narrow", Arial, sans-serif;
    font-weight: 500;
    font-stretch: condensed;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 40px;
    line-height: 2.5rem;
    color: #fff;
    text-shadow: 2px 4px 8px rgba(0, 0, 0, .55);
    margin-top: 20px;
}
.youtubeLibrary form{
    display: flex;
   
}
.youtubeLibrary form input{
    background-color: #111111;
    border-width: 1px;
    border-style: solid;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #ffffff;
    font-family: Bungee;
    font-size: 17px;
    line-height: 44px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: uppercase;
    padding-top: 12px;
    padding-right: 31px;
    padding-bottom: 12px;
    padding-left: 31px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 700px;
    border-right: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
}
.youtubeLibrary form .btn-grad{
    width:40%;
    margin-top:-0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    
}
.youtubeLibrary .videoContainer{
    display: flex;
    flex-wrap: wrap;
    gap:50px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 150vh;
    overflow: auto;
}
.videoContainer .videoItem{
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
}








/* Common styles for both buttons */
.btn-video-copy, .btn-video-delete {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-right: 10px;
  }
  
  /* Specific styles for the "Copy Link" button */
  .btn-video-copy {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  
  .btn-video-copy:hover {
    background-color: #45a049; /* Darker green */
  }
  
  .btn-video-delete {
    background-color: #f44336; /* Red */
    color: white;
  }
  
  .btn-video-delete:hover {
    background-color: #e53935; /* Darker red */
  }
  

  .videoActionsPodcast{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .videoActionsPodcast p{
    color:#fff;
  }
  .responsiveVideoPodcast{
    width:100%;
    max-width: 450px;
    
  }
  .videoItemPodcast{
    padding:15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .55);
    
    
  }