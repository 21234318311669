.affiliateUsersWrapper{
    width: calc(100% - 230px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-image: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(../../Assets/banner.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin-left: 230px; /* Leaves 230px space from the left */
    flex-direction: column;
    padding:30px;
}
.thirdHeadingOne{
    font-size: 20px;
    line-height: 60px;
    color: #fff;
    font-family:  sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.searcDivAffiliate{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.searcDivAffiliate .searchDivPartTwo{
    display: flex;
    gap:20px;
    flex-wrap: wrap;
}
.searcDivAffiliate input{
    width:20%;
}
.searcDivAffiliate .searchDivPartTwo h1{
    font-size: 16px;
    line-height: 60px;
    color: #fff;
    font-family:  sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    padding:0 20px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid #fff;
    
}

.userItemsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    margin-top:50px;
    
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
}

.userItemsHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    background-color: black;
    color: crimson;
    padding: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
}

.userItemsHeader h1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 0;
}

.userItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #f2f2f2;
}


.userItem h1 {
    font-size: 14px;
    text-align: center;
    margin: 0;
    color: #333;
}

.userItem .viewButton {
    padding: 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width:100px;
    margin-left: auto;
    margin-right: auto;
    
}
.userItem .deleteButton{
    width:100px;
    margin-left: auto;
    margin-right: auto;
}
.userItem img{
    width:60px;
    height:60px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.viewButton:hover {
    background-color: #0056b3;
}

.userItem:first-child {
    border-top: 1px solid #ddd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.userItem:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}




















.affiliateDetailsWrapper{
    display: flex;
}
.affiliateDetailsWrapper .imgAffiliate{
    width:170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-basis: 15%;
}
.affiliateDetailsWrapper .imgAffiliate img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
.affiliateDetailsWrapperTwo{
    flex-basis: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.affiliateDetailsWrapperTwo h1{
   font-family: Arial, Helvetica, sans-serif;
   font-size: 14px;
   color:#fff;
   margin:17px;
   padding:8px 10px;
   width:250px;
   background-color: rgba(0, 0, 0, 0.6);
}
.approveButton{
    padding:10px 0;
    text-align: center;
    width:200px;
    cursor: pointer;
    color:#fff;
    background-color: crimson;
    border:2px solid #fff;
    outline: none;
}


.emailTemplateParent, .userItemsParent{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emailTemplateWrapper {
    margin-top: 20px;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width:50%;
  }
  
  .emailTemplateWrapper h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .emailForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .formGroup input,
  .formGroup textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .formGroup input[readonly] {
    background-color: #e9e9e9;
    color: #777;
  }
  
  .sendEmailButton {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .sendEmailButton:hover {
    background-color: #218838;
  }
  

.activeFilter{
    background-color: #0056b3;
}
  
@media (max-width: 767px){
    .affiliateUsersWrapper{
        width: 100%;
        margin-left: 0; /* Leaves 230px space from the left */
        padding:30px;
        padding-top:100px;
    }
    .thirdHeadingOne{
        font-size: 18px;
       }
    .searcDivAffiliate{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
        gap:30px;
    }
    .searcDivAffiliate .searchDivPartTwo{
        display: flex;
        gap:10px;
        justify-content: center;
        align-items: center;
        
    }
    .searcDivAffiliate input{
        width:80%;
    }
    .searcDivAffiliate .searchDivPartTwo h1{
        font-size: 13px;
        line-height: 60px;
        padding:0 10px;
        height:30px;
        border:2px solid #fff;
        
    }
    
    .userItemsWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top:50px;
        max-width: 800px;
        overflow: auto;
    }
    .userItemsHeader {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        background-color: black;
        color: crimson;
        padding: 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 800px; /* Make sure it takes full width */
        box-sizing: border-box; /* Ensure padding doesn't affect width */
        margin: 0; /* Remove any potential margin */
    }
    
    .userItemsHeader h1 {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
       padding: 20px;
    }
    
    .userItem {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #ddd;
        background-color: #f2f2f2;
        width:800px;
    }
    
    
    .userItem h1 {
        font-size: 14px;
        text-align: center;
        margin: 20px;
        color: #333;
    }
    
    .userItem .viewButton {
        padding: 10px 0;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s ease;
        width:100px;
        margin-left: auto;
        margin-right: auto;
        
    }
    .userItem .deleteButton{
        width:100px;
        margin-left: auto;
        margin-right: auto;
    }
    .userItem img{
        width:60px;
        height:60px;
        border-radius: 50%;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
    .viewButton:hover {
        background-color: #0056b3;
    }
    
    .userItem:first-child {
        border-top: 1px solid #ddd;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    
    .userItem:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    
  
    



    
.affiliateDetailsWrapper{
    display: flex;
    flex-direction: column;
}
.affiliateDetailsWrapper .imgAffiliate{
    width:170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-basis: 15%;
}
.affiliateDetailsWrapper .imgAffiliate img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
.affiliateDetailsWrapperTwo{
    flex-basis: 100%;
    margin-top:20px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -10px;
  
}
.affiliateDetailsWrapperTwo h1{

    margin:10px;
   padding:8px 10px;
   width:250px;
}
.approveButton{
    padding:10px 0;
    text-align: center;
    width:200px;
    cursor: pointer;
    color:#fff;
    background-color: crimson;
    border:2px solid #fff;
    outline: none;
}


.emailTemplateParent, .userItemsParent{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emailTemplateWrapper {
    margin-top: 20px;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width:100%;
  }
  
  .emailTemplateWrapper h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .emailForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .formGroup input,
  .formGroup textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .formGroup input[readonly] {
    background-color: #e9e9e9;
    color: #777;
  }
  
  .sendEmailButton {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .sendEmailButton:hover {
    background-color: #218838;
  }
  

    
    
    
    
     
}






/* Container Styles */
.styledPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .styledPopup .popup-content {
    width: 400px;
    animation: fadeIn 0.3s ease-in-out;
    
  }
  
  /* Form Styles */
  .styledPopup form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
  
  }
  
  .styledPopup .form-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
     flex-direction: column;
    gap: 8px;
  }
  
  .styledPopup label {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
  
  .styledPopup input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
  }
  
  .styledPopup input:focus {
    border-color: #007bff;
  }
  
  /* Buttons */
  .styledPopup .form-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  