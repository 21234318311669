/* General Wrapper */
.sponsor-wrapper {
    margin: 0 auto;
    padding: 50px;
    font-family: 'Arial', sans-serif;
    color: #333;
    width:calc(100%-230px);
    margin-left: 230px;
    background: linear-gradient(136.74deg, #e7e7e9 19.43%, #fff 89.46%);
    box-shadow: 0 2px 7px rgba(0, 0, 0, .15);
    min-height: 100vh;
  }
  .sponsor-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* Heading */
  .sponsor-heading {
    color: #d20a0a;
    font-size: 48px;
    text-align: center;
    font-family: 'UfcSansRegular';
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    margin-bottom: 5px;
  }
  
  /* Add Sponsor Button */
  .add-sponsor-btn {
    background: linear-gradient(216.01deg, #d20a0a 26.18%, #9e0808 93.27%);
    color: #fff;
    cursor: pointer;
    font-family: 'UfcSansRegular';
    font-weight: 600;
    font-size: 22px;
    outline: none;
    padding: 12px 24px;
    text-align: center;
    width: 165px;
    text-decoration: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .25);
    border:none;
    outline: none;
}
  
  .add-sponsor-btn:hover {
    background-color: #2980b9;
  }
  
  /* Form Wrapper */
  .add-sponsor-form {
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Input Fields */
  .sponsor-input, .sponsor-textarea, .sponsor-file-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .sponsor-textarea {
    height: 100px;
    resize: none;
  }
  
  .sponsor-file-input {
    padding: 5px;
  }
  
  /* Submit Button */
  .submit-sponsor-btn {
    width: 100%;
    padding: 12px;
    background-color: #27ae60;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-sponsor-btn:hover {
    background-color: #1e8449;
  }
  /* Sponsor Grid */
.sponsor-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px);
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  /* Sponsor Card */
  .sponsor-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    width: 350px; /* Fixed card width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .sponsor-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
  
  /* Sponsor Image */
  .sponsor-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  /* Sponsor Name */
  .sponsor-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #34495e;
  }
  
  /* Sponsor Description */
  .sponsor-description {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 15px;
    line-height: 1.5;
  }
  
  /* Sponsor Links */
  .sponsor-link, .sponsor-insta {
    display: inline-block;
    margin: 5px 10px;
    padding: 8px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;
    background-color: #3498db;
    transition: background-color 0.3s ease;
  }
  
  .sponsor-link:hover, .sponsor-insta:hover {
    background-color: #2980b9;
  }
  
  /* Edit Button */
  .edit-sponsor-btn {
    margin-top: 10px;
    padding: 8px 15px;
    font-size: 0.9rem;
    background-color: #f1c40f;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
  }
  
  .edit-sponsor-btn:hover {
    background-color: #d4ac0d;
  }
  
  /* Delete Button */
  .delete-sponsor-btn {
    margin-top: 10px;
    padding: 8px 15px;
    font-size: 0.9rem;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-sponsor-btn:hover {
    background-color: #c0392b;
  }
  
  /* No Sponsors Message */
  .no-sponsors-message {
    text-align: center;
    font-size: 1.2rem;
    color: #7f8c8d;
    margin-top: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .add-sponsor-btn, .submit-sponsor-btn {
      font-size: 0.9rem;
    }
  
    .sponsor-name {
      font-size: 1.3rem;
    }
  
    .sponsor-description {
      font-size: 0.9rem;
    }
  }
  