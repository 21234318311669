@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

.guide-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 160px;
    padding-bottom: 70px;
    width: 100%;
    min-height: 100vh; /* Full-screen height */
    position: relative;
    background: linear-gradient(97.03deg, #000000 30.31%, #3F3F3F 90.51%);
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
    gap:70px;
    position: relative;
}
.guide-wrapper .arrowUp{
    position: fixed;
    bottom: 80px;
    right: 20px;
    width:40px;
    height: 40px;
    background-color: #D20A0A;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transform: 0.5s ease;
}
.guide-wrapper .arrowUp i{
    color:#fff;
    font-weight: 200;
    font-size: 19px;
}
.guide-wrapper .guideHeadingMain{
    color: #fff;
    font-size: 65px;
    text-align: center;
    font-family: 'UFCSansMedium';
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 5px;
    font-style: italic;
    position: relative;
}
.guide-wrapper .guideHeadingMain span{
    color:#D20A0A;
}

.guide-wrapper .guideHeadingMain::before,
.guide-wrapper .guideHeadingMain::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 90%; /* Adjust to control line length */
  height: 4px; /* Line thickness */
  background-color: red; /* Red background color */
  transform: translateY(-50%); /* Centers the line vertically with text */
}

.guide-wrapper .guideHeadingMain::before {
  left: -100%; /* Adjust spacing from text */
}

.guide-wrapper .guideHeadingMain::after {
  right: -100%; /* Adjust spacing from text */
}



.guides-content{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.guides-content .guide-text-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.guides-content .guide-text-center p{
    color: white;
    width: 50%;
    padding: 12px 0 48px 0;
    font-size: 16px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}





.guide-flex-row-left{
    display: flex;
    gap:30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:90px;
}
.guide-flex-row-left h2{
    font-size: 45px;
    text-align: center;
    font-family: 'UfcSansRegular';
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 5px;
    position: relative;
    color:red;
    letter-spacing: 2px;
}
.guide-flex-row-left img{
    width:500px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.45);
}
.guide-flex-row-left p{
    color: white;
    font-size: 22px;
    text-align: center;
    font-family: 'UfcSansRegular', Arial, sans-serif;
    line-height: 1.38;
    width:50%;
    letter-spacing: 0.01071em;
}
.guide-flex-row-left-div{
    display: flex;
    gap:50px;
    justify-content: center;
    align-items: center;
}

.tableOfContents{
    width: 65%;
    min-height: 50vh;
    margin:10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.tableOfContents .contentTitle{
    color: #D20A0A;
    font-size: 65px;
    text-align: center;
    font-family: 'UFCSansMedium';
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 25px;
    font-style: italic;
    position: relative;
    
}
.tableOfContents a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding-bottom: 10px;
    border-bottom: 2px solid #fff;
    margin-bottom: 10px;
    font-family: 'UfcSansRegular', Arial, sans-serif;
    letter-spacing: 0.01071em;
    font-size: 12px;
}
.tableOfContents a:hover{
    color: #D20A0A;
    
}







@media (max-width: 767px){

    .guide-wrapper{
        justify-content: flex-start;
        padding-top: 160px;
        padding-bottom: 70px;
        gap:70px;
        overflow-x: hidden;
    }
    .guide-wrapper .guideHeadingMain{
        font-size: 45px;
        }
    
    .guide-wrapper .guideHeadingMain::before,
    .guide-wrapper .guideHeadingMain::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 20%; /* Adjust to control line length */
      height: 4px; /* Line thickness */
      background-color: red; /* Red background color */
      transform: translateY(-50%); /* Centers the line vertically with text */
    }
    
    .guide-wrapper .guideHeadingMain::before {
      left: -26%; /* Adjust spacing from text */
    }
    
    .guide-wrapper .guideHeadingMain::after {
      right: -26%; /* Adjust spacing from text */
    }
    
    
    
    .guides-content{
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
    }
    
    
    .guides-content .guide-text-center p{
        color: white;
        width: 85%;
        padding: 12px 0 48px 0;
        }
    
    
    
    
    
    .guide-flex-row-left{
        gap:30px;
        flex-direction: column;
        margin-top:30px;
    }
    .guide-flex-row-left h2{
        font-size: 28px;
        }
    .guide-flex-row-left img{
        width:300px;
    }
    .guide-flex-row-left p{
        font-size: 22px;
        width:85%;
       }
    .guide-flex-row-left-div{
        gap:30px;
        flex-direction: column;
        }
    






        
.tableOfContents{
    width: 90%;
    margin:20px 0;
    }
.tableOfContents .contentTitle{
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 25px;
  }
.tableOfContents a{
    padding-bottom: 10px;
    margin-bottom: 10px;
    letter-spacing: 0.01071em;
    font-size: 12px;
}


}