/* Popup background overlay */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup content box */
  .popup-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
    animation: popupFadeIn 0.3s ease-in-out;
  }
  
  /* Popup heading */
  .popup-content h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333333;
  }
  
  /* Popup action buttons */
  .popup-actions {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  
  .popup-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .popup-actions button:first-child {
    background-color: #e74c3c;
    color: white;
  }
  
  .popup-actions button:first-child:hover {
    background-color: #c0392b;
  }
  
  .popup-actions button:last-child {
    background-color: #bdc3c7;
    color: #333333;
  }
  
  .popup-actions button:last-child:hover {
    background-color: #95a5a6;
  }
  
  /* Popup fade-in animation */
  @keyframes popupFadeIn {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  .searchbar-fights{
    width: 100%;
    max-width: 600px;
    border:none;
    border:3px solid #ffc000;
    background:rgba(0, 0, 0, 0.87);
    color:#ffc000;
    outline: none;
    box-shadow: 0 10px 20px rgba(255,255,255,0.04);
    padding: 10px;
  }