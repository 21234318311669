
/* Container for the thread list */
.thread-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 130px;
    padding-bottom: 70px;
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(../../Assets/banner.png); /* Black background */
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh; /* Full-screen height */
  }
  
  /* Forum heading styling */
  .thread-list-container h1 {
   color: #fff;
    font-size: 45px;
    line-height: 79px;
    text-align: center;
    padding: 27px 0;
    width: 1120px;
    font-family: 'Bungee', sans-serif;
    text-transform: uppercase;
    -webkit-text-shadow: 6px 5px 6px #201e1f;
    -moz-text-shadow: 6px 5px 6px #201e1f;
    -o-text-shadow: 6px 5px 6px #201e1f;
    text-shadow: 6px 5px 6px #201e1f;
    margin-bottom: 10px;
  }
  
  /* Thread item styling */
  .thread-item {
    background-color: rgba(0,0,0,0.8); /* Dark grey background */
    margin-bottom: 20px;
    text-align: left;
    cursor: pointer;
    width: 100%; /* Make it stretch to the container width */
    max-width: 700px; /* Set a max-width for responsiveness */
    max-height: 300px; /* Max height of each thread item */
    overflow: hidden; /* Hide overflow to handle text overflow */
    transition: transform 0.3s ease, background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
  
  /* Adjust the hover effect */
  .thread-item:hover {
    background-color: #333333; /* Slightly lighter grey on hover */
    transform: translateY(-5px); /* Lift effect */
  }
  .toFlex{
    display: flex;
    gap: 30px;
    padding-bottom: 10px;
  }
  .create-thread-btn{
    padding:10px 30px;
    border:none;
    outline: none;
    background-color:#d30c23;
    color:#fff;
    font-family: sans-serif;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.5);
    margin-bottom: 30px;
    font-family: 'Bungee', sans-serif;
   
  }
  /* Thread title with size adjustments */
  .thread-title {
    font-size: 19px;
    font-weight: 200;
    color: #fff; /* Red for the title */
    margin-bottom: 10px;
    white-space: nowrap; /* Prevent title overflow */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis if title is too long */
    width: 100%;
    background-color: #720e0c;
    font-family: 'Bungee', sans-serif;
    padding:7px;
    
  }
  
  /* Thread metadata (posted by, date) */
  .thread-meta {
    font-size: 1.0rem;
    color: #999; /* Light grey for metadata */
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 7px;
    font-family: 'UfcSansRegular', Arial, sans-serif;
  }
  
  /* Thread body text (limited height) */
  .thread-body {
    font-size: 1.1rem;
    color: #f0f0f0;
    margin-bottom: 15px;
    max-height: 100px; /* Limit the body text to avoid overflow */
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 7px;
    font-family: 'UfcSansRegular', Arial, sans-serif;
  }
  
  /* Thread views and replies */
  .thread-views, .thread-replies {
    font-size: 1.1rem;
    color: #ccc;
    margin-bottom: 5px;
    padding-left: 7px;
    font-family: 'UfcSansRegular', Arial, sans-serif;
  }
  
  /* Thread likes */
  .thread-likes {
    font-size: 1.1rem;
    color: #ff3b3b; /* Red for likes */
    margin-bottom: 5px;
    font-family: 'UfcSansRegular', Arial, sans-serif;
  }
  

  .adminSideThreadsContainer{ 
  width: calc(100% - 230px);
margin-left: 230px;    
    
  }

  .no-posts-message{
    color:#fff;
  }

















































  /* Container for the thread list */
.thread-list-container-updated {
  display: flex;
  flex-direction: column;
  padding-top: 170px;
  padding-bottom: 70px;
  width: 100%;
  padding-left: 72px;
  padding-right: 30px;
  min-height: 100vh; /* Full-screen height */
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.7)),url(../../Assets/backone.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Forum heading styling */
.thread-list-container-updated h1 {
  font-size: 60px;
  margin-bottom: 10px;
  font-family: 'UfcSansRegular', Arial, sans-serif;color: #16B4CA;
}


/* Thread item styling */
.thread-item-updated {
  margin-bottom: 20px;
  cursor: pointer;
  width: 100%; /* Make it stretch to the container width */
  overflow: hidden; /* Hide overflow to handle text overflow */
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  justify-content: left;
  align-items: left;
  gap:20px;
  max-width: 800px;
  background: #fff;
  padding:20px;
  box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.15);
}
.respondbtn{
  padding: 0.5rem 1.75rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    color:#fff;
  border:none;
  background: #F80110;
  border-radius: 20px;
  outline:none;
}
.thread-item-updated .userImage{
  width:130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thread-item-updated .userImage img{
  width:100%;
  height:100%;
  object-fit: cover;
}
/* Adjust the hover effect */
.thread-item:hover {
  background-color: #333333; /* Slightly lighter grey on hover */
  transform: translateY(-5px); /* Lift effect */
}
.toFlex-updated{
  display: flex;
  gap: 30px;
  
  margin-top:10px;
}
.create-thread-btn-updated{
  padding:10px 30px;
  border:none;
  outline: none;
  background-color:#d30c23;
  color:#fff;
  font-family: sans-serif;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.4);
  margin-bottom: 30px;
  font-family: 'Bungee', sans-serif;
  width:200px;

 
}
/* Thread title with size adjustments */
.thread-title-updated {
  font-size: 27px;
  margin-top: 10px;
  font-family: 'UfcSansRegular', Arial, sans-serif;
  color: #000;

}
.toFlexHeading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  }
  .toFlexHeading .communityRulesIcon {
    position: absolute;
    top: 70px;
    right: 16px;
  }
  
  .toFlexHeading .communityRulesIcon i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    position: relative;
  }
  
  .toFlexHeading .communityRulesIcon::after {
    content: "Community Rules & Regulations"; /* Tooltip text */
    display: none;
    position: absolute;
    bottom: -5px; /* Adjust depending on your layout */
    right: 30px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    white-space: nowrap;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .toFlexHeading .communityRulesIcon:hover::after {
    display: block;
  }
  
  
  /* Thread metadata (posted by, date) */
.thread-meta-updated {
  font-size: 22px;
  margin-bottom: 10px;
  
  font-family: 'UfcSansRegular', Arial, sans-serif;
  color: #e2211c;
}

/* Thread body text (limited height) */
.thread-body-updated {
  font-size: 20px;
  margin-bottom: 5px;
  font-family: sans-serif;
}

/* Thread views and replies */
.thread-views-updated, .thread-replies-updated {
  font-size: 21px;
  margin-bottom: 5px;
  font-family: 'UfcSansRegular', Arial, sans-serif;
}

/* Thread likes */
.thread-likes-updated {
  font-size: 21px;
  color: #16B4CA; /* Red for likes */
  margin-bottom: 5px;
  font-family: 'UfcSansRegular', Arial, sans-serif;
}


.adminSideThreadsContainer{ 
width: calc(100% - 230px);
margin-left: 230px;    
  
}

.no-posts-message-updated{
  color:#fff;
}






/* Mobile-specific styling */
@media (max-width: 767px){

  /* Container for the thread list */
  .thread-list-container-updated {
    padding-top: 140px;
    padding-bottom: 70px;
    padding-left: 10px;
    padding-right: 10px;
   }
  
  /* Forum heading styling */
  .thread-list-container-updated h1 {
    font-size: 30px;
   }
  
  
  /* Thread item styling */
  .thread-item-updated {
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    padding:20px;
    }
  .respondbtn{
    padding: 0.5rem 1.75rem;
      border-radius: 20px;
   }
  .thread-item-updated .userImage{
    width:130px;
    height: 130px;
      
  }
  .thread-item-updated .userImage img{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  /* Adjust the hover effect */
  .thread-item:hover {
    background-color: #333333; /* Slightly lighter grey on hover */
    transform: translateY(-5px); /* Lift effect */
  }
  .toFlex-updated{
    display: flex;
    gap: 20px;
    margin-top:10px;
    justify-content: center;
    align-items: center;
  }
  
  /* Thread title with size adjustments */
  .thread-title-updated {
    font-size: 26px;
    
  }
  .toFlexHeading{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  /* Thread metadata (posted by, date) */
  .thread-meta-updated {
    font-size: 21px;
  }
  
  /* Thread body text (limited height) */
  .thread-body-updated {
    font-size: 19px;
    
  }
  
  /* Thread views and replies */
  .thread-views-updated, .thread-replies-updated {
    font-size: 16px;
    
  }
  
  /* Thread likes */
  .thread-likes-updated {
    font-size: 16px;
  }
  
}