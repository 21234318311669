.adminWrapper {
    width: calc(100% - 230px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(../../Assets/banner.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin-left: 230px; /* Leaves 230px space from the left */

    
}
.adminWrapper .frontPageHeading{
    color:#fff;
}
.adminWrapper .controls{
    display: flex;
    color: #fff;
    gap:40px;
    font-size: 26px;
    cursor: pointer;
}
.adminWrapper .controls h5:hover{
    color:#ccc;
}

.adminWrapper .controls .active{
    color:yellowgreen;
}
.adminWrapper .popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.adminWrapper .popupContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.adminWrapper .popupActions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.adminWrapper .confirmBtn {
  background: #ff4d4f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.adminWrapper .confirmBtn:hover {
  background: #d9363e;
}

.adminWrapper .cancelBtn {
  background: #ccc;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.adminWrapper .cancelBtn:hover {
  background: #bbb;
}


/* Box Container */
  .boxesContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 900px;
  }
  
  /* Box Styles */
  .boxx {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
    min-width: 230px;
    max-width: 230px;
    height:170px;
    color: #fff;
    font-family: 'Orbitron', sans-serif;
    cursor: pointer;
    }
  
  /* Custom Colors for Each Box */
  .totalMatches {
    background-color: #ff6b6b;
  }
  
  .shadowTemplates {
    background-color: #4dabf7;
  }
  
  .registeredUsers {
    background-color: #81c784;
  }
  
  .affiliates {
    background-color: #ffca28;
  }
  

.visitors {
  background-color: #f39c12; /* Unique color for Visitors */
}
  /* Typography for Boxy, Game-Like Feel */
  .boxx h2 {
    font-size: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  .boxx p {
    font-size: 1.2rem;
    color: #f8f8f8;
    font-family: 'VT323', monospace;
  }
  
  .boxx i {
    margin-top:10px;
    font-size: 2rem;
    margin-bottom: 10px;
    color: #fff;
  }
  
  /* Hover Effect */
  .boxx:hover {
    transform: translateY(-5px);
    transition: 0.3s ease;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  }
 
  
  


/* Parent container */
.popupUsersJoinedCustom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0;
  padding: 20px;
}

/* Popup content styling */
.popup-content-custom {
  background-color: rgba(0, 0, 0, 0.85); /* Dark background for strong contrast */
  padding: 30px;
  border-radius: 15px;
  max-width: 700px;
  width: 100%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8); /* Deep shadow for depth */
  text-align: center;
  animation: fadeIn 0.5s ease;
  border: 2px solid #ff4500; /* Vibrant border to grab attention */
}

/* Heading Styling */
.popup-content-custom h3 {
  font-size: 28px;
  color: #ff4500; /* Bright highlight color */
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

/* Table styling */
.popup-content-custom table {
  width: 100%;
  border-collapse: separate; /* Create spacing between borders */
  margin-top: 20px;
  background: rgba(255, 69, 0, 0.1); /* Subtle colored background */
  border-radius: 10px;
  overflow: hidden;
}

.popup-content-custom th, 
.popup-content-custom td {
  padding: 15px;
  text-align: left;
  font-size: 16px;
  color: #fff; /* White text for clarity */
  border-bottom: 1px solid rgba(255, 69, 0, 0.5); /* Orange highlights */
}

.popup-content-custom th {
  background-color: rgba(255, 69, 0, 0.3); /* Light orange for headers */
  font-weight: bold;
  text-transform: uppercase;
}

.popup-content-custom td {
  background-color: rgba(255, 255, 255, 0.1); /* Slight transparency for rows */
}

/* Row hover effect */
.popup-content-custom tbody tr:hover {
  background-color: rgba(255, 69, 0, 0.2); /* Orange glow effect */
  transition: background-color 0.3s ease;
}

/* Optional: Fade-in effect for the popup */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* For a bold button effect */
.popup-content-custom button {
  padding: 12px 25px;
  background: #ff4500; /* Strong orange color for buttons */
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background 0.3s ease;
}

.popup-content-custom button:hover {
  background: #e03b00; /* Darker orange on hover */
}


@media (max-width: 767px){
    .adminWrapper {
        width: 100%;
        margin-left: 0; 
        z-index: 100;
        
        padding-top: 120px;
    }
    .adminWrapper .frontPageHeading{
        text-align: center;
    }
    .adminWrapper .controls{
        display: flex;
        color: #fff;
        gap:40px;
        font-size: 26px;
        cursor: pointer;
    }
    
.adminWrapper .controls{
    gap:20px;
    font-size: 21px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}


/* Box Container */
.boxesContainer {
  padding-bottom: 100px;
}

/* Box Styles */
.boxx {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  color: #fff;
  font-family: 'Orbitron', sans-serif;
  cursor: pointer;
  margin:0 auto;
}















/* Parent container */
.popupUsersJoinedCustom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0;
  padding: 20px;
}

/* Popup content styling */
.popup-content-custom {
  padding: 30px;
  border-radius: 15px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8); /* Deep shadow for depth */
  text-align: center;
  animation: fadeIn 0.5s ease;
  border: 2px solid #ff4500; /* Vibrant border to grab attention */
  overflow: auto;
}

/* Heading Styling */
.popup-content-custom h3 {
  font-size: 16px;
  color: #ff4500; /* Bright highlight color */
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

/* Table styling */
.popup-content-custom table {
  width: 100%;
  border-collapse: separate; /* Create spacing between borders */
  margin-top: 20px;
  background: rgba(255, 69, 0, 0.1); /* Subtle colored background */
  border-radius: 10px;
  overflow: auto;
}

.popup-content-custom th, 
.popup-content-custom td {
  padding: 15px;
  text-align: left;
  font-size: 13px;
  color: #fff; /* White text for clarity */
  border-bottom: 1px solid rgba(255, 69, 0, 0.5); /* Orange highlights */
}

.popup-content-custom th {
  background-color: rgba(255, 69, 0, 0.3); /* Light orange for headers */
  font-weight: bold;
  text-transform: uppercase;
}

.popup-content-custom td {
  background-color: rgba(255, 255, 255, 0.1); /* Slight transparency for rows */
}

/* Row hover effect */
.popup-content-custom tbody tr:hover {
  background-color: rgba(255, 69, 0, 0.2); /* Orange glow effect */
  transition: background-color 0.3s ease;
}

/* Optional: Fade-in effect for the popup */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* For a bold button effect */
.popup-content-custom button {
  padding: 12px 25px;
  background: #ff4500; /* Strong orange color for buttons */
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background 0.3s ease;
}

.popup-content-custom button:hover {
  background: #e03b00; /* Darker orange on hover */
}

     
}




















